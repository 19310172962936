import {
  Box,
  Button,
  Container,
  Link,
  Paper,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import Copyright from "../atoms/Copyright";
import DiscordLogo from "../atoms/DiscordLogo";
import InstagramLogo from "../atoms/InstagramLogo";

export default function Footer() {
  const theme = useTheme();

  return (
    <Box mt={2}>
      <Paper elevation={1} square sx={{ padding: "2rem 0" }}>
        <Container>
          <Stack direction="row" mb={5} justifyContent="space-around">
            <Copyright />

            <Stack spacing={2} direction="column">
              <Tooltip title="Discord: flysimroutes" arrow>
                <Button
                  LinkComponent={Link}
                  href="https://discord.gg/Gfb7akW6Yx"
                  target="_blank"
                >
                  <DiscordLogo fill={theme.palette.text.primary} />
                </Button>
              </Tooltip>

              <Tooltip title="Instagram: flysimroutes" arrow>
                <Button
                  LinkComponent={Link}
                  href="https://www.instagram.com/flysimroutes/"
                  target="_blank"
                >
                  <InstagramLogo fill={theme.palette.text.primary} />
                </Button>
              </Tooltip>
            </Stack>

            <Stack spacing={2}>
              <Typography variant="h6">Legal</Typography>
              <Link sx={{ textDecoration: "none" }} href="/imprint">
                Imprint
              </Link>
            </Stack>
          </Stack>
        </Container>
      </Paper>
    </Box>
  );
}
