import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import FlightIcon from "@mui/icons-material/Flight";
import AirlinesIcon from "@mui/icons-material/Airlines";
import HeadsetMicIcon from "@mui/icons-material/HeadsetMic";
import { ReactNode } from "react";
import LocalizedDate from "./LocalizedDate";
import LocalizedTime from "./LocalizedTime";
import SimbriefPlanButton from "./SimbriefPlanButton";
import CountryFlag from "./CountryFlag";
import NotAvailableText from "./NotAvailableText";
import PhotoLink from "./PhotoLink";
import { RatingSmall } from "./RatingSmall";

function Scenery({
  scenery,
  icao,
}: {
  scenery: { developer: string; marketplace: string; rating: number } | null;
  icao: string;
}) {
  return scenery ? (
    <Tooltip title={`${scenery.developer} (${scenery.marketplace})`} arrow>
      <Stack direction="row" alignItems="baseline" justifyContent="start">
        {icao}
        <RatingSmall rating={scenery.rating} />
      </Stack>
    </Tooltip>
  ) : (
    <span>{icao}</span>
  );
}

type FlightCardProps = {
  flight: {
    airlineIcao: string;
    airline?: { logoUrl?: string };
    departureTime: Date;
    number?: string;
    callsign?: string;
    distance: number;
    aircraftType: string;
    registration?: string;
    origin: {
      name: string;
      iata?: string;
      icao: string;
      country: string;
      scenery?: {
        developer: string;
        marketplace: string;
        rating: number;
      } | null;
    };
    destination: {
      name: string;
      iata?: string;
      icao: string;
      country: string;
      scenery?: {
        developer: string;
        marketplace: string;
        rating: number;
      } | null;
    };
    departureGate?: string;
    arrivalGate?: string;
  };
  actions?: ReactNode;
};

export default function FlightCard({ flight, actions }: FlightCardProps) {
  const theme = useTheme();

  return (
    <Card>
      <CardMedia
        sx={{
          backgroundColor: theme.flightCard.backgroundColor,
          margin: "0 auto",
          padding: "10px",
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid container spacing={1}>
            <Grid item xs={12} md={4}>
              {flight.airline?.logoUrl ? (
                <img
                  src={`/assets/airlines/${flight.airline?.logoUrl}`}
                  alt={flight.airlineIcao}
                  width="auto"
                />
              ) : (
                <Typography variant="h6" color="primary.dark">
                  {flight.airlineIcao}
                </Typography>
              )}
            </Grid>

            <Grid item xs={6} md={3}>
              <Stack direction="column">
                <Typography variant="h6" component="div" color="primary">
                  <LocalizedDate date={flight.departureTime} />
                </Typography>
                <Typography color="secondary.dark">
                  <LocalizedTime date={flight.departureTime} />
                </Typography>
              </Stack>
            </Grid>

            <Grid item xs={6} md={5}>
              <Stack direction="column">
                <Typography
                  variant="h6"
                  sx={{ textAlign: "right" }}
                  color="primary"
                >
                  <FlightIcon style={{ verticalAlign: "middle" }} />{" "}
                  {flight.number}
                </Typography>

                <Typography color="secondary.dark" sx={{ textAlign: "right" }}>
                  <AirlinesIcon style={{ verticalAlign: "middle" }} />{" "}
                  {flight.airlineIcao}{" "}
                  <HeadsetMicIcon style={{ verticalAlign: "middle" }} />{" "}
                  {flight.callsign}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </Stack>
      </CardMedia>
      <CardContent>
        <Grid container spacing={0}>
          <Grid item xs={3} sx={{ fontWeight: "bold" }}>
            Depart:
          </Grid>
          <Grid item xs={7}>
            <Typography noWrap>
              <CountryFlag country={flight.origin.country} />{" "}
              {flight.origin.name}/{flight.origin.iata}
            </Typography>
          </Grid>
          <Grid item xs={2} justifyContent="end">
            <Scenery
              icao={flight.origin.icao}
              scenery={flight.origin.scenery}
            />
          </Grid>

          <Grid item xs={3} sx={{ fontWeight: "bold" }}>
            Arrive:
          </Grid>
          <Grid item xs={7}>
            <Typography noWrap>
              <CountryFlag country={flight.destination.country} />{" "}
              {flight.destination.name}/{flight.destination.iata}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Scenery
              icao={flight.destination.icao}
              scenery={flight.destination.scenery}
            />
          </Grid>

          <Grid item xs={3} sx={{ fontWeight: "bold" }}>
            Distance:
          </Grid>
          <Grid item xs={3}>
            {flight.distance} NM
          </Grid>

          <Grid item xs={4} sx={{ fontWeight: "bold" }}>
            Departure Gate:
          </Grid>
          <Grid item xs={2}>
            {flight.departureGate ? flight.departureGate : <NotAvailableText />}
          </Grid>

          <Grid item xs={3} sx={{ fontWeight: "bold" }}>
            Aircraft:
          </Grid>
          <Grid item xs={3}>
            {flight.aircraftType}

            <PhotoLink registration={flight.registration} />
          </Grid>

          <Grid item xs={4} sx={{ fontWeight: "bold" }}>
            Arrival Gate:
          </Grid>
          <Grid item xs={2}>
            {flight.arrivalGate ? flight.arrivalGate : <NotAvailableText />}
          </Grid>
        </Grid>
      </CardContent>

      <CardActions>
        <SimbriefPlanButton flight={flight} />

        {actions}
      </CardActions>
    </Card>
  );
}
