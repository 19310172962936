import {
  Autocomplete,
  Button,
  FormControl,
  FormHelperText,
  Stack,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Scenery } from "../../models/scenery";
import AirportAutocomplete, { SelectedAirport } from "./AirportAutocomplete";
import AirportOption from "../atoms/AirportOption";
import DeleteIcon from "@mui/icons-material/Delete";
import request from "graphql-request";
import { useQuery } from "@tanstack/react-query";
import { graphql } from "../gql/gql";

const majorAirports = graphql(/* GraphQL */ `
  query majorAirports($take: Int!) {
    majorAirports(pagination: { take: $take }) {
      icao
      iata
      name
      country
      latlng {
        lat
        lng
      }
    }
  }
`);

export type SceneryFormProps = {
  onCancel: () => void;
  onSubmit: (scenery: Scenery) => void;
  scenery?: Scenery;
};

export default function SceneryForm(props: SceneryFormProps) {
  const [airport, setAirport] = useState<SelectedAirport | null>(
    props.scenery?.airport || null
  );
  const [airportError, setAirportError] = useState<string | null>(null);
  const [platform, setPlatform] = useState<string>(
    props.scenery?.platform || "MSFS"
  );
  const [platformError, setPlatformError] = useState<string | null>(null);
  const [developer, setDeveloper] = useState<string>(
    props.scenery?.developer || ""
  );
  const [developerError, setDeveloperError] = useState<string | null>(null);
  const [marketplace, setMarketplace] = useState<string>(
    props.scenery?.marketplace || ""
  );
  const [marketplaceError, setMarketplaceError] = useState<string | null>(null);

  const { data } = useQuery({
    queryKey: ["majorAirports"],
    queryFn: async () => request("/graphql", majorAirports, { take: 100 }),
  });

  const hasErrors =
    !!airportError ||
    !!platformError ||
    !!developerError ||
    !!marketplaceError ||
    !airport?.icao ||
    !platform ||
    !developer ||
    !marketplace;

  useEffect(() => {
    // validate that the ICAO code is 4 characters long
    if (airport?.icao.length !== 4 && airport?.icao.length > 0) {
      setAirportError("ICAO codes must be 4 characters long");
      return;
    }

    setAirportError(null);
  }, [airport]);

  useEffect(() => {
    if (marketplace.length <= 3 && marketplace.length > 0) {
      setMarketplaceError(
        "Marketplace name must be at least 4 characters long"
      );
      return;
    }

    if (marketplace.length > 50) {
      setMarketplaceError(
        "Marketplace name must be at most 50 characters long"
      );
      return;
    }

    setMarketplaceError(null);
  }, [marketplace]);

  useEffect(() => {
    if (platform.length < 3 && platform.length > 0) {
      setPlatformError("Platform must be at least 3 characters long");
      return;
    }

    if (platform.length > 50) {
      setPlatformError("Platform name must be at most 50 characters long");
      return;
    }

    setPlatformError(null);
  }, [platform]);

  useEffect(() => {
    // validate that the ICAO code is 4 characters long
    if (developer.length <= 3 && developer.length > 0) {
      setDeveloperError("Developer name must be at least 4 characters long");
      return;
    }

    if (developer.length > 50) {
      setDeveloperError("Developer name must be at most 50 characters long");
      return;
    }

    setDeveloperError(null);
  }, [developer]);

  return (
    <Stack>
      <Stack>
        <Stack spacing={2} sx={{ margin: "2rem 0" }}>
          <FormControl>
            {airport ? (
              <Stack direction="row" alignItems="center" mb={2}>
                <Button onClick={() => setAirport(null)} color="error">
                  <DeleteIcon />
                </Button>
                <AirportOption airport={airport} />
              </Stack>
            ) : (
              <AirportAutocomplete
                airports={data?.majorAirports ?? []}
                label="Airport"
                error={!!airportError}
                helperText={airportError}
                onAirportSelect={(a) => setAirport(a)}
              />
            )}
          </FormControl>

          <FormControl>
            <Autocomplete
              id="platform"
              freeSolo
              options={[]}
              inputValue={platform}
              onInputChange={(event: any, newValue: string | null) => {
                setPlatform(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Platform"
                  aria-describedby="platform"
                  error={!!platformError}
                  helperText={platformError}
                />
              )}
            />
            <FormHelperText id="platform">
              Platform the scenery is installed on
            </FormHelperText>
          </FormControl>

          <FormControl>
            <Autocomplete
              id="developer"
              freeSolo
              options={[]}
              inputValue={developer}
              onInputChange={(event: any, newValue: string | null) => {
                setDeveloper(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Developer"
                  aria-describedby="developer"
                  error={!!developerError}
                  helperText={developerError}
                />
              )}
            />
            <FormHelperText id="developer">
              Name of the scenery developer
            </FormHelperText>
          </FormControl>

          <FormControl>
            <Autocomplete
              id="marketplace"
              freeSolo
              options={[]}
              inputValue={marketplace}
              onInputChange={(event: any, newValue: string | null) => {
                setMarketplace(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Marketplace"
                  aria-describedby="marketplace"
                  error={!!marketplaceError}
                  helperText={marketplaceError}
                />
              )}
            />
            <FormHelperText id="marketplace">
              Name of the marketplace you bought the scenery from
            </FormHelperText>
          </FormControl>
        </Stack>

        <Stack direction="row" spacing={2}>
          <Button variant="outlined" onClick={props.onCancel}>
            Cancel
          </Button>
          <Button
            variant="contained"
            disabled={hasErrors}
            onClick={() =>
              props.onSubmit({
                id: props.scenery?.id,
                icao: airport.icao,
                developer: developer.trim(),
                marketplace: marketplace.trim(),
                platform: platform.trim(),
                rating: props.scenery?.rating || 0,
                airport: null,
              })
            }
          >
            Save
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
}
