import { Chip, ChipProps, useTheme } from "@mui/material";
import { stringToColour } from "../lib/color";

export default function ColoredChip(props: ChipProps) {
  const theme = useTheme();

  return (
    <Chip
      color="primary"
      {...props}
      sx={{
        backgroundColor: stringToColour(props.label as string),
        color: theme.palette.getContrastText(
          stringToColour(props.label as string)
        ),
      }}
    />
  );
}
