// @ts-ignore
import { Circle, Marker, Polyline, Tooltip } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-defaulticon-compatibility";
import "leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.css";
import {
  LatLngBounds,
  DivIcon,
  PointExpression,
  LatLngExpression,
} from "leaflet";
import RoomIcon from "@mui/icons-material/Room";
import { renderToString } from "react-dom/server";
import { useTheme } from "@mui/material";
import { CSSProperties, useEffect, useState } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import Map from "../atoms/Map";
import { nmToMeters } from "../lib/geo";
import { unique } from "../lib/map";
import AirportTooltip from "../atoms/AirportTooltip";
import { RatingSmall } from "../atoms/RatingSmall";

const defaultBounds: LatLngBounds = new LatLngBounds([
  [-20.566406250000004, 33.652758730604866],
  [34.62890625000001, 62.775689347098606],
]);

type Flight = {
  origin: AirportFragment;
  destination: AirportFragment;
};

export type AirportFragment = {
  icao: string;
  iata?: string;
  name: string;
  country: string;
  latlng: {
    lat: number;
    lng: number;
  };
  scenery?: {
    developer: string;
    marketplace: string;
    rating: number;
  } | null;
};

type AirportFragment2 = AirportFragment & {
  type: string;
};

function DistanceRings({
  distances,
  center,
}: {
  distances: number[];
  center: LatLngExpression;
}) {
  const theme = useTheme();

  return (
    <>
      {distances
        .sort((a, b) => b - a)
        .map((distance, i) => (
          <Circle
            key={`${i}-${center}`}
            center={center}
            radius={nmToMeters(distance)}
            pathOptions={{
              color: theme.map.circles.border,
              fillColor: theme.map.circles.fill,
            }}
            fillOpacity={0.1 + i * 0.02}
            opacity={0.1 + i * 0.1}
          >
            <Tooltip direction="top">{distance} NM</Tooltip>
          </Circle>
        ))}
    </>
  );
}

type FlightsMapProps = {
  airports: AirportFragment2[];
  onMoveEnd?: (zoom: number, bounds: LatLngBounds) => void;
  onOriginSelect: (airport: AirportFragment) => void;
  onDestinationSelect: (airport: AirportFragment) => void;
  onRouteSelect?: (
    origin: AirportFragment,
    destination: AirportFragment
  ) => void;
  origin?: AirportFragment;
  destination?: AirportFragment;
  flights: {
    origin: AirportFragment;
    destination: AirportFragment;
  }[];
  sx?: CSSProperties;
  distanceRings?: number[];
};

export default function FlightsMap({
  airports,
  onMoveEnd,
  onDestinationSelect,
  onOriginSelect,
  onRouteSelect,
  origin,
  destination,
  flights,
  sx,
  distanceRings,
}: FlightsMapProps) {
  const rings = distanceRings ?? [100, 250, 500, 1000, 2500];

  const theme = useTheme();
  const [mouseOverAiport, setMouseOverAirport] =
    useState<AirportFragment2 | null>(null);

  const onMoved = (zoom: number, bounds: LatLngBounds) => {
    onMoveEnd && onMoveEnd(zoom, bounds);
    setBounds(bounds);
    setZoom(zoom);
  };

  const [zoom, setZoom] = useState(
    parseInt(localStorage.getItem("flysimroutes.mapZoom") ?? "5")
  );

  let storedBounds = JSON.parse(
    localStorage.getItem("flysimroutes.mapPosition") ?? null
  ) as any | undefined;

  const [bounds, setBounds] = useState<LatLngBounds | null>(
    storedBounds?.se && storedBounds?.ne
      ? new LatLngBounds(storedBounds.se, storedBounds.ne)
      : defaultBounds
  );

  // store last state of the map in localstorage
  useEffect(() => {
    localStorage.setItem("flysimroutes.mapZoom", zoom.toString());
    localStorage.setItem(
      "flysimroutes.mapPosition",
      JSON.stringify({ se: bounds.getSouthWest(), ne: bounds.getNorthEast() })
    );
  }, [zoom, bounds]);

  useEffect(() => {
    const bounds = flights.reduce((acc: LatLngBounds, flight: Flight) => {
      if (!acc) {
        return new LatLngBounds(
          flight.origin.latlng,
          flight.destination.latlng
        );
      } else {
        return acc
          .extend(flight.origin.latlng)
          .extend(flight.destination.latlng);
      }
    }, null);

    if (!bounds) {
      return;
    }

    setBounds(bounds);
    setZoom(5);
  }, [flights]);

  const routes = new Set(
    flights.map((flight) => {
      return {
        origin: flight.origin,
        destination: flight.destination,
      };
    })
  );

  return (
    <Map onMoveEnd={onMoved} zoom={zoom} bounds={bounds} sx={sx}>
      {unique(airports, (a) => a.icao).map((airport) => {
        let iconSize: PointExpression = [20, 20];
        let fill = theme.palette.primary.dark;
        let highlightFill = theme.palette.success.main;
        let icon = null;

        if (
          origin?.icao === airport.icao ||
          destination?.icao === airport.icao
        ) {
          fill = highlightFill;
        } else if (
          airport.type === "heliport" ||
          airport.type === "seaplane_base"
        ) {
          fill = theme.palette.text.disabled;
        } else if (airport.type === "small_airport") {
          fill = theme.palette.text.secondary;
        } else if (airport.type === "medium_airport") {
          fill = theme.palette.primary.light;
        }

        if (
          mouseOverAiport?.icao === airport.icao &&
          (mouseOverAiport?.icao === origin?.icao ||
            mouseOverAiport?.icao === destination?.icao)
        ) {
          icon = (
            <RemoveCircleIcon
              style={{ fill: theme.palette.error.main }}
              sx={{ width: "2rem" }}
            />
          );
        } else if (mouseOverAiport?.icao === airport.icao) {
          icon = (
            <AddCircleIcon
              style={{ fill: highlightFill }}
              sx={{ width: "2rem" }}
            />
          );
        } else if (airport.scenery != null) {
          icon = <RatingSmall rating={airport.scenery.rating} />;
        } else {
          icon = <RoomIcon style={{ fill }} sx={{ width: "0.5rem" }} />;
        }

        const divIcon = new DivIcon({
          html: renderToString(icon),
          iconSize: iconSize,
          iconAnchor: iconSize.map((x) => x / 2) as PointExpression,
        });

        return (
          <Marker
            position={[airport.latlng.lat, airport.latlng.lng]}
            icon={divIcon}
            key={airport.icao}
            eventHandlers={{
              mouseover: () => {
                setMouseOverAirport(airport);
              },
              mouseout: () => {
                setMouseOverAirport(null);
              },
              click: () => {
                if (origin?.icao === airport.icao) {
                  onOriginSelect(null);
                } else if (destination?.icao === airport.icao) {
                  onDestinationSelect(null);
                } else if (!origin) {
                  onOriginSelect(airport);
                } else if (!destination) {
                  onDestinationSelect(airport);
                }
              },
            }}
          >
            {mouseOverAiport?.icao === airport.icao && (
              <AirportTooltip airport={airport} />
            )}
          </Marker>
        );
      })}

      {origin && <DistanceRings distances={rings} center={origin.latlng} />}
      {destination && (
        <DistanceRings distances={rings} center={destination.latlng} />
      )}

      {Array.from(routes).map((route, i) => {
        const positions: LatLngExpression[] = [
          route.origin.latlng,
          route.destination.latlng,
        ];

        let color = theme.palette.primary.main;

        if (route.origin.scenery || route.destination.scenery) {
          color = theme.palette.success.main;
        } else if (route.origin.scenery || route.destination.scenery) {
          color = theme.palette.warning.main;
        }

        return (
          <Polyline
            pathOptions={{ color }}
            positions={positions}
            key={i}
            eventHandlers={{
              click: () => {
                onRouteSelect(route.origin, route.destination);
              },
            }}
          />
        );
      })}
    </Map>
  );
}
