import {
  AppBar,
  Button,
  Dialog,
  Divider,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Slide,
  Toolbar,
  Typography,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { graphql } from "../gql/gql";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import request from "graphql-request";
import SnackbarAlert from "../atoms/SnackbarAlert";
import { SceneryPackage } from "../gql/graphql";
import CheckIcon from "@mui/icons-material/Check";
import ColoredChip from "../atoms/ColoredChip";

const sceneryPackages = graphql(/* GraphQL */ `
  query sceneryPackages($take: Int!, $skip: Int!) {
    sceneryPackages(pagination: { take: $take, skip: $skip }) {
      id
      name
      icaos
      platform
      developer
      createdAt
    }
  }
`);

const addSceneryPackages = graphql(/* GraphQL */ `
  mutation addSceneryPackages($ids: [ID!]!) {
    addSceneryPackages(ids: $ids) {
      id
    }
  }
`);

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export type SceneryPackagesDialogProps = {
  open: boolean;
  onClose: () => void;
};

export default function SceneryPackagesDialog(
  props: SceneryPackagesDialogProps
) {
  const [selected, setSelected] = useState<SceneryPackage[]>([]);
  const queryClient = useQueryClient();

  const handleClose = () => {
    props.onClose();
  };

  const { data, error, errorUpdatedAt } = useQuery({
    queryKey: ["sceneryPackages"],
    queryFn: async () =>
      request("/graphql", sceneryPackages, { take: 1000, skip: 0 }),
  });

  const toggleSelected = (sceneryPackage: SceneryPackage) => {
    if (selected.includes(sceneryPackage)) {
      setSelected(selected.filter((x) => x !== sceneryPackage));
    } else {
      setSelected(selected.concat(sceneryPackage));
    }
  };

  const addSceneryPackageMutation = useMutation({
    mutationFn: async () =>
      request("/graphql", addSceneryPackages, {
        ids: selected.map((x) => x.id),
      }),
    onSuccess: () => {
      setSelected([]);
      queryClient.invalidateQueries({ queryKey: ["sceneries"] });
      handleClose();
    },
  });

  const handleSave = () => {
    addSceneryPackageMutation.mutate();
  };

  return (
    <React.Fragment>
      <SnackbarAlert
        created={errorUpdatedAt}
        message={
          error
            ? "Error loading scenery packages. Please try again later."
            : null
        }
        onClose={() => null}
        type="error"
      />

      <Dialog
        fullScreen
        open={props.open && !error}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Choose scenery packages to add
            </Typography>
            <Button autoFocus color="inherit" onClick={handleSave}>
              save
            </Button>
          </Toolbar>
        </AppBar>
        <List>
          {data?.sceneryPackages.map((sceneryPackage: any) => (
            <React.Fragment key={sceneryPackage.id}>
              <ListItemButton onClick={() => toggleSelected(sceneryPackage)}>
                <ListItemIcon>
                  {selected.includes(sceneryPackage) && (
                    <CheckIcon color="success" />
                  )}
                </ListItemIcon>

                <ListItemText
                  primary={
                    <span>
                      {sceneryPackage.name}{" "}
                      <ColoredChip
                        label={sceneryPackage.platform}
                        size="small"
                      />
                    </span>
                  }
                  secondary={
                    <>
                      {sceneryPackage.developer},{" "}
                      {new Date(sceneryPackage.createdAt).toLocaleDateString()}
                    </>
                  }
                />
              </ListItemButton>
              <Divider />
            </React.Fragment>
          ))}
        </List>
      </Dialog>
    </React.Fragment>
  );
}
