import { Stack, Typography } from "@mui/material";
import { LatLngLiteral } from "leaflet";
import { distance } from "../lib/geo";
import CountryFlag from "./CountryFlag";

type AirportOptionProps = {
  airport: {
    icao: string;
    iata?: string;
    name: string;
    country: string;
    latlng: {
      lat: number;
      lng: number;
    };
  };
  distanceReference?: LatLngLiteral;
};

export default function AirportOption({
  airport,
  distanceReference,
}: AirportOptionProps) {
  return (
    <Stack direction="row" spacing={1} alignItems="top">
      <Stack direction="column" alignItems="center">
        <Typography fontSize="1.05rem">{airport.icao}</Typography>

        {distanceReference && (
          <Typography variant="body2" fontSize="0.7rem" noWrap>
            {Math.round(distance(airport.latlng, distanceReference))} NM
          </Typography>
        )}
      </Stack>

      <Stack direction="row" spacing={1}>
        <CountryFlag country={airport.country} />

        <Typography variant="body2">
          {airport.name}/{airport.iata}
        </Typography>
      </Stack>
    </Stack>
  );
}
