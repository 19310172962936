import {
  AppBar,
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  Link,
  List,
  ListItem as MuiListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

import LoginProfile from "./LoginProfile";
import { User } from "../gql/graphql";
import MenuIcon from "@mui/icons-material/Menu";
import { useState } from "react";
import FlightIcon from "@mui/icons-material/Flight";
import SaveIcon from "@mui/icons-material/Save";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PersonIcon from "@mui/icons-material/Person";
import HomeIcon from "@mui/icons-material/Home";
import Copyright from "../atoms/Copyright";
import AssignmentIcon from "@mui/icons-material/Assignment";
import SettingsIcon from "@mui/icons-material/Settings";
import Footer from "./Footer";
import StarIcon from "@mui/icons-material/Star";

function isActive(href: string) {
  return window.location.pathname === href;
}

function ListItem(props: {
  title: string;
  href: string;
  icon?: React.ReactNode;
}) {
  const theme = useTheme();

  return (
    <MuiListItem
      disablePadding
      sx={{
        backgroundColor: isActive(props.href)
          ? theme.palette.action.hover
          : "inherit",
      }}
    >
      <ListItemButton LinkComponent={Link} href={props.href}>
        {props.icon && <ListItemIcon>{props.icon}</ListItemIcon>}

        <ListItemText primary={props.title} inset={!props.icon} />
      </ListItemButton>
    </MuiListItem>
  );
}

export type HeaderProps = {
  showDrawer?: boolean;
  darkMode: boolean;
  user: User | null;
  children: React.ReactNode;
};

export default function Header(props: HeaderProps) {
  const showDrawer = props.showDrawer === false ? false : true;
  const theme = useTheme();

  const [mobileOpen, setMobileOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  let links = [
    {
      title: "Dashboard",
      href: "/dashboard",
      icon: <DashboardIcon />,
    },
    {
      title: "Flights",
      href: "/flights",
      icon: <FlightIcon />,
    },
    {
      title: "Saved Flights",
      href: "/flightplans",
      icon: <SaveIcon />,
    },
    {
      title: "Sceneries",
      href: "/sceneries",
      icon: <StarIcon />,
    },
    {
      item: <Divider />,
    },
    {
      title: "Simbrief OFP",
      href: "/simbrief",
      icon: <AssignmentIcon />,
    },
  ];

  if (!props.user) {
    links = [
      {
        title: "Home",
        href: "/",
        icon: <HomeIcon />,
      },
      {
        title: "Create Account",
        href: "/login",
        icon: <PersonIcon />,
      },
    ];
  }

  const drawerWidth = showDrawer ? 230 : 0;

  const drawer = (
    <Stack minHeight="100vh" justifyContent="space-between">
      <Stack>
        <Toolbar>
          <Stack direction="row" alignContent="space-between">
            <img src="/logo.png" alt="FlySimRoutes" width={32} height={32} />
          </Stack>
        </Toolbar>

        <List>
          <Divider />

          {links.map((link, index) => {
            return link.item !== undefined ? (
              <div key={index}>{link.item}</div>
            ) : (
              <ListItem
                title={link.title}
                href={link.href}
                icon={link.icon}
                key={index}
              />
            );
          })}

          <Divider />
        </List>
      </Stack>

      <Stack>
        <List>
          <Divider />
          {props.user && (
            <ListItem
              title="Settings"
              href="/profile/settings"
              icon={<SettingsIcon />}
            />
          )}

          <ListItem title="Imprint" href="/imprint" icon={null} />

          <Divider />

          <MuiListItem color="secondary">
            <ListItemText>
              <Copyright />
            </ListItemText>
          </MuiListItem>
        </List>
      </Stack>
    </Stack>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          {showDrawer && (
            <Box sx={{ mr: 1, display: { xs: "block", sm: "none" } }}>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={handleDrawerToggle}
              >
                <MenuIcon />
              </IconButton>
            </Box>
          )}

          <Box sx={{ mr: 1 }}>
            <RouterLink to={props.user ? "/dashboard" : "/"}>
              <img src="/logo.png" alt="logo" width={32} height={32} />
            </RouterLink>
          </Box>
          <Box sx={{ flexGrow: 1 }}>
            <Button
              variant="text"
              color="inherit"
              component={RouterLink}
              to={props.user ? "/dashboard" : "/"}
            >
              <Typography
                variant="h6"
                component="div"
                sx={{ textTransform: "none" }}
              >
                FlySimRoutes
              </Typography>
            </Button>
          </Box>
          <Stack direction="row" spacing={2}>
            {props.user ? (
              <Stack direction="row" alignItems="center">
                <LoginProfile user={props.user} />
              </Stack>
            ) : (
              <Stack direction="row" alignItems="center">
                <Button LinkComponent={Link} href="/login">
                  <Stack spacing={2} direction="row" alignItems="center">
                    <Typography color={theme.palette.background.default}>
                      Sign in
                    </Typography>
                  </Stack>
                </Button>

                <Button
                  LinkComponent={Link}
                  href="/login"
                  variant="outlined"
                  sx={{
                    borderColor: theme.palette.background.default,
                    color: theme.palette.background.default,
                  }}
                >
                  Create Account
                </Button>
              </Stack>
            )}
          </Stack>
        </Toolbar>
      </AppBar>

      {showDrawer && (
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="drawer"
        >
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onTransitionEnd={handleDrawerTransitionEnd}
            onClose={handleDrawerClose}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>

          <Drawer
            variant="permanent"
            sx={{
              display: { xs: "none", sm: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
            open
          >
            {drawer}
          </Drawer>
        </Box>
      )}

      <Stack
        component="main"
        sx={{
          flexGrow: 1,
          marginTop: "48px",
          width: { xs: "100%", sm: `calc(100% - ${drawerWidth}px)` },
          minHeight: "100vh",
        }}
        justifyContent="space-between"
      >
        <Box>{props.children}</Box>

        <Footer />
      </Stack>
    </Box>
  );
}
