import { IconButton, Tooltip } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";

export type SaveIconButtonProps = {
  isSaved?: boolean;
  onClick: () => void;
};

export default function SaveIconButton(props: SaveIconButtonProps) {
  if (props.isSaved) {
    return (
      <Tooltip title="Saved in 'Saved Flights'" arrow>
        <IconButton color="success">
          <SaveIcon />
        </IconButton>
      </Tooltip>
    );
  }

  return (
    <Tooltip title="Save for later" arrow>
      <IconButton color="primary" onClick={props.onClick}>
        <SaveIcon />
      </IconButton>
    </Tooltip>
  );
}
