import { Button, Grid, Paper, Stack, Tooltip, Typography } from "@mui/material";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import ModeOfTravelIcon from "@mui/icons-material/ModeOfTravel";
import DeleteIcon from "@mui/icons-material/Delete";
import AirportAutocomplete from "./AirportAutocomplete";
import { distance } from "../lib/geo";
import AirportOption from "../atoms/AirportOption";
import AircraftAutocomplete from "./AircraftAutocomplete";
import { Aircraft } from "../../models/aircraft";
import { LatLngLiteral } from "leaflet";
import AirlineAutocomplete from "./AirlineAutocomplete";
import { Airline } from "../gql/graphql";
import DistanceSlider from "../atoms/DistanceSlider";

type AirportFragment = {
  icao: string;
  iata?: string;
  name: string;
  country: string;
  latlng: {
    lat: number;
    lng: number;
  };
};

function DeleteableAirport({
  label,
  airport,
  availableAirports,
  onSelect,
  distanceReference,
}: {
  label: string;
  airport: AirportFragment;
  availableAirports: AirportFragment[];
  onSelect: (airport: AirportFragment) => void;
  distanceReference?: LatLngLiteral;
}) {
  return (
    <Stack direction="row">
      {airport ? (
        <Stack direction="row" alignItems="flex-start">
          <Button
            color="error"
            onClick={() => onSelect(null)}
            variant="text"
            sx={{ minWidth: "2rem", padding: "0.2rem", margin: "0.2rem" }}
          >
            <DeleteIcon />
          </Button>

          <Stack direction="column">
            <Typography
              variant="body2"
              color="text.secondary"
              fontSize="0.8rem"
            >
              {label}:
            </Typography>

            <AirportOption airport={airport} />
          </Stack>
        </Stack>
      ) : (
        <AirportAutocomplete
          label={label}
          airports={availableAirports}
          onAirportSelect={onSelect}
          error={false}
          helperText={null}
          distanceReference={distanceReference}
        />
      )}
    </Stack>
  );
}

type FlightsFilterProps = {
  airports: AirportFragment[];
  origin?: AirportFragment;
  destination?: AirportFragment;
  aircraftsSelected: Aircraft[] | null;
  airlinesSelected: Airline[] | null;
  distanceSelected: { min?: number; max?: number };
  onSwapOriginDestination: () => void;
  onOriginChange: (airport?: AirportFragment) => void;
  onDestinationChange: (airport?: AirportFragment) => void;
  onAircraftsChange: (aircrafts: Aircraft[] | null) => void;
  onAirlinesChange: (airline: Airline[]) => void;
  onDistanceChange: (distance: { min?: number; max?: number }) => void;
};

export default function FlightsFilter(props: FlightsFilterProps) {
  return (
    <Stack>
      <Paper elevation={2} sx={{ padding: "1rem" }}>
        <Grid
          container
          spacing={1}
          justifyItems="center"
          justifyContent="space-between"
        >
          <Grid item xs={12} md={2} overflow="hidden">
            <DeleteableAirport
              label="Depart"
              airport={props.origin}
              availableAirports={props.airports}
              onSelect={props.onOriginChange}
              distanceReference={props.destination?.latlng}
            />
          </Grid>

          <Grid item xs={12} md={1} overflow="hidden">
            <Stack direction="column">
              <Button
                onClick={() => {
                  props.onSwapOriginDestination();
                }}
              >
                <Tooltip title="Swap from and to" arrow>
                  <SwapHorizIcon />
                </Tooltip>
              </Button>

              {props.origin && props.destination && (
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  sx={{ fontSize: "0.7rem" }}
                >
                  <ModeOfTravelIcon sx={{ marginRight: "10px" }} />
                  {distance(props.origin.latlng, props.destination.latlng)} NM
                </Stack>
              )}
            </Stack>
          </Grid>

          <Grid item xs={12} md={2} overflow="hidden">
            <DeleteableAirport
              label="Arrive"
              airport={props.destination}
              availableAirports={props.airports}
              onSelect={props.onDestinationChange}
              distanceReference={props.origin?.latlng}
            />
          </Grid>

          <Grid item xs={12} md={2}>
            <DistanceSlider
              value={props.distanceSelected}
              min={0}
              max={2000}
              unlimitedMax={10000}
              markCount={4}
              onChange={(newValue) => {
                props.onDistanceChange(newValue);
              }}
            />
          </Grid>

          <Grid item xs={12} md={2}>
            <AirlineAutocomplete
              airlines={[]}
              label="Airlines"
              error={false}
              helperText={""}
              selectedAirlines={props.airlinesSelected}
              onAirlinesSelect={props.onAirlinesChange}
            />
          </Grid>

          <Grid item xs={12} md={2}>
            <AircraftAutocomplete
              label="Aircraft Type"
              error={false}
              helperText={""}
              selectedAircrafts={props.aircraftsSelected}
              onAircraftsSelected={props.onAircraftsChange}
            />
          </Grid>
        </Grid>
      </Paper>
    </Stack>
  );
}
