import { Alert as MuiAlert, Slide, Snackbar } from "@mui/material";
import { useEffect, useState } from "react";

export type Alert = {
  created: Date | number;
  message: string;
};

type SnackbarAlertProps = Alert & {
  onClose: () => void;
  type: "error" | "success" | "warning";
};

export default function SnackbarAlert(props: SnackbarAlertProps) {
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    if (props.message) {
      setOpen(true);

      const timeoutId = setTimeout(() => {
        setOpen(false);
      }, 6000);

      return () => clearTimeout(timeoutId);
    }
  }, [props.created, props.message]);

  const handleClose = () => {
    setOpen(false);
    props.onClose();
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      TransitionComponent={Slide}
      color={props.type}
      onClose={handleClose}
    >
      <MuiAlert severity={props.type} variant="filled" sx={{ width: "100%" }}>
        {props.message}
      </MuiAlert>
    </Snackbar>
  );
}
