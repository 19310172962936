import { Typography, useTheme } from "@mui/material";

export default function NotAvailableText() {
  const theme = useTheme();

  return (
    <Typography noWrap variant="body2" color={theme.palette.text.disabled}>
      N/A
    </Typography>
  );
}
