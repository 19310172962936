import { LatLngBounds } from "leaflet";
import { Box, Grid, Skeleton, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { Airport } from "../../models/airport";
import FlightsFilter from "../components/FlightsFilter";
import FlightsCardGrid from "../components/FlightsCardGrid";
import FlightsMap from "../components/FlightsMap";
import SnackbarAlert from "../atoms/SnackbarAlert";
import { Aircraft } from "../../models/aircraft";
import request from "graphql-request";
import { useQuery } from "@tanstack/react-query";
import { graphql } from "../gql/gql";
import { Airline, AirportType } from "../gql/graphql";
import Breadcrumbs from "../atoms/Breadcrumbs";
import { unique } from "../lib/map";
import { useDebouncedCallback } from "use-debounce";
import ScrollingLayout from "../components/ScrollingLayout";
import Pagination from "../atoms/Pagination";
import { FlightFilterInput, filterToURL, urlToFilter } from "../FlightFilter";
import { useSearchParams } from "react-router-dom";

const defaultBounds = new LatLngBounds([0, 0], [0, 0]);

const searchAirportsInBounds = graphql(/* GraphQL */ `
  query searchAirportsInBounds(
    $sw: LatLngInput!
    $ne: LatLngInput!
    $types: [AirportType]!
    $take: Int
  ) {
    searchAirportsInBounds(sw: $sw, ne: $ne, types: $types) {
      icao
      iata
      name
      type
      country
      latlng {
        lat
        lng
      }
      scenery {
        id
        platform
        developer
        marketplace
        rating
      }
    }

    majorAirports(pagination: { take: $take, skip: 0 }) {
      icao
      iata
      name
      type
      country
      latlng {
        lat
        lng
      }
      scenery {
        id
        platform
        developer
        marketplace
        rating
      }
    }
  }
`);

const getFlights = graphql(/* GraphQL */ `
  query flights($pagination: PaginationOptions!, $filter: FlightsFilterInput!) {
    flights(pagination: $pagination, filter: $filter) {
      take
      skip
      total
      results {
        number
        isSaved
        airlineIcao
        airline {
          logoUrl
        }
        aircraftType
        registration
        callsign
        departureTime
        distance
        origin {
          icao
          iata
          name
          country
          type
          latlng {
            lat
            lng
          }
          scenery {
            developer
            marketplace
            rating
          }
        }
        destination {
          icao
          iata
          name
          country
          type
          latlng {
            lat
            lng
          }
          scenery {
            developer
            marketplace
            rating
          }
        }
        departureGate
        arrivalGate
      }
    }
  }
`);

const getAirport = graphql(/* GraphQL */ `
  query airport($icao: ID!) {
    airport(icao: $icao) {
      icao
      iata
      name
      type
      country
      latlng {
        lat
        lng
      }
      scenery {
        platform
        developer
        marketplace
        rating
      }
    }
  }
`);

export default function FlightsPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [skip, setSkip] = useState(0);
  const [bounds, setBounds] = useState<LatLngBounds | null>(defaultBounds);
  const [zoom, setZoom] = useState<number | null>(null);
  const [types, setTypes] = useState<AirportType[]>([AirportType.LargeAirport]);
  const [filter, setFilter] = useState<FlightFilterInput>(
    urlToFilter(searchParams)
  );

  const airports = useQuery({
    queryKey: ["airports", zoom, bounds, types],
    queryFn: async () =>
      request("/graphql", searchAirportsInBounds, {
        sw: bounds?.getSouthWest().wrap(),
        ne: bounds?.getNorthEast().wrap(),
        types: types,
      }),
  });

  const flights = useQuery({
    queryKey: ["flights", filter, skip],
    queryFn: async () =>
      request("/graphql", getFlights, {
        pagination: { take: 50, skip },
        filter: {
          originIcao: filter.originIcao,
          destinationIcao: filter.destinationIcao,
          airlineIcao: filter.airlines?.map((a) => a.icao),
          aircraftIcao: filter.aircrafts?.map((a) => a.id),
          distance: filter.distance,
        },
      }),
    enabled:
      !!filter.originIcao ||
      !!filter.destinationIcao ||
      filter.airlines?.length > 0 ||
      filter.aircrafts?.length > 0,
  });

  const origin = useQuery({
    queryKey: ["origin", filter.originIcao],
    queryFn: async () =>
      request("/graphql", getAirport, {
        icao: filter.originIcao,
      }),
    enabled: !!filter.originIcao,
  });

  const destination = useQuery({
    queryKey: ["destination", filter.destinationIcao],
    queryFn: async () =>
      request("/graphql", getAirport, {
        icao: filter.destinationIcao,
      }),
    enabled: !!filter.destinationIcao,
  });

  useEffect(() => {
    if (zoom >= 10) {
      setTypes([
        AirportType.LargeAirport,
        AirportType.MediumAirport,
        AirportType.SmallAirport,
      ]);
    } else if (zoom >= 8) {
      setTypes([AirportType.LargeAirport, AirportType.MediumAirport]);
    } else {
      setTypes([AirportType.LargeAirport]);
    }
  }, [zoom]);

  const refreshData = (zoom: number, bounds: LatLngBounds) => {
    setZoom(zoom);
    setBounds(bounds);
  };

  const debouncedRefreshData = useDebouncedCallback(refreshData, 300);

  const resetFilters = () => {
    setFilter({ distance: { min: 0, max: 10000 } });
  };

  useEffect(() => {
    setSearchParams(filterToURL(filter), { replace: true });
    setSkip(0);
  }, [filter, setSearchParams]);

  let availableAirports =
    (airports.data?.searchAirportsInBounds?.length > 0
      ? airports.data?.searchAirportsInBounds
      : airports.data?.majorAirports) ?? [];

  let airportsInMap = availableAirports;

  // overwrite availableAirports if flights are available
  if (flights?.data?.flights?.results?.length > 0) {
    availableAirports = unique(
      flights.data.flights.results.flatMap((f) => [f.origin, f.destination]),
      (item) => item.icao
    ) as any[];

    airportsInMap = unique(
      airportsInMap.concat(availableAirports),
      (item) => item.icao
    );
  }

  return (
    <ScrollingLayout>
      <SnackbarAlert
        created={flights.errorUpdatedAt}
        message={flights.error ? "Error loading flights." : null}
        onClose={() => null}
        type="error"
      />

      <Stack direction="column">
        <Breadcrumbs
          pages={[
            { name: "Dashboard", href: "/dashboard" },
            { name: "Flights" },
          ]}
        />

        <Stack direction="column" paddingBottom={2}>
          <FlightsFilter
            airports={availableAirports ?? []}
            aircraftsSelected={filter.aircrafts ?? []}
            origin={origin?.data?.airport}
            destination={destination?.data?.airport}
            airlinesSelected={filter.airlines ?? []}
            distanceSelected={filter.distance ?? { min: 0 }}
            onSwapOriginDestination={() => {
              setFilter({
                ...filter,
                originIcao: filter.destinationIcao,
                destinationIcao: filter.originIcao,
              });
            }}
            onDestinationChange={(destination) =>
              setFilter({ ...filter, destinationIcao: destination?.icao })
            }
            onOriginChange={(origin) =>
              setFilter({ ...filter, originIcao: origin?.icao })
            }
            onAircraftsChange={(aircrafts: Aircraft[] | null) =>
              setFilter({ ...filter, aircrafts })
            }
            onAirlinesChange={(airlines: Airline[]) =>
              setFilter({ ...filter, airlines })
            }
            onDistanceChange={(distance) => {
              setFilter({
                ...filter,
                distance: { min: distance.min, max: distance.max },
              });
            }}
          />
        </Stack>

        <Box height={300}>
          <FlightsMap
            airports={airportsInMap.concat(availableAirports)}
            onMoveEnd={debouncedRefreshData}
            onRouteSelect={(origin, destination) => {
              setFilter({
                ...filter,
                originIcao: origin.icao,
                destinationIcao: destination.icao,
              });
            }}
            onDestinationSelect={(airport?: Airport) =>
              setFilter({ ...filter, destinationIcao: airport?.icao })
            }
            onOriginSelect={(airport) =>
              setFilter({ ...filter, originIcao: airport?.icao })
            }
            flights={flights.data?.flights?.results ?? []}
            origin={origin?.data?.airport ?? null}
            destination={destination?.data?.airport ?? null}
          />
        </Box>
      </Stack>

      <Stack direction="column" spacing={2} mt={1}>
        {flights.isLoading ? (
          <Grid container spacing={1}>
            {[1, 2, 3].map((i) => (
              <Grid item xs={12} md={6} xl={4} key={i}>
                <Skeleton variant="rounded" height={200} />
              </Grid>
            ))}
          </Grid>
        ) : (
          <Stack direction="column" alignItems="center" spacing={1}>
            <Pagination
              take={flights?.data?.flights?.take}
              skip={flights?.data?.flights?.skip}
              total={flights?.data?.flights?.total}
              onChange={(skip) => setSkip(skip)}
            />

            <FlightsCardGrid
              enableSave={true}
              enableDelete={false}
              flights={flights.data?.flights?.results ?? []}
              filter={filter}
              origin={origin?.data?.airport}
              destination={destination?.data?.airport}
              onDestinationChange={(icao) =>
                setFilter({ ...filter, destinationIcao: icao })
              }
              onOriginChange={(icao) =>
                setFilter({ ...filter, originIcao: icao })
              }
              onResetFilters={resetFilters}
            />

            <Pagination
              take={flights?.data?.flights?.take}
              skip={flights?.data?.flights?.skip}
              total={flights?.data?.flights?.total}
              onChange={(skip) => setSkip(skip)}
            />
          </Stack>
        )}
      </Stack>

      <SnackbarAlert
        created={airports.errorUpdatedAt}
        message={airports.error ? "Error loading airports." : null}
        onClose={() => null}
        type="error"
      />
    </ScrollingLayout>
  );
}
