import { LatLngLiteral } from "leaflet";

function distanceInKm(lat1: number, lon1: number, lat2: number, lon2: number) {
  const R = 6371; // km
  const dLat = toRad(lat2 - lat1);
  const dLon = toRad(lon2 - lon1);
  const lat1x = toRad(lat1);
  const lat2y = toRad(lat2);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1x) * Math.cos(lat2y);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c;

  return d;
}

export function distance(a: LatLngLiteral, b: LatLngLiteral) {
  return Math.round(kmToNm(distanceInKm(a.lat, a.lng, b.lat, b.lng)));
}

// Converts numeric degrees to radians
function toRad(Value) {
  return (Value * Math.PI) / 180;
}

export function kmToNm(km: number) {
  return km / 1.852;
}

export function nmToMeters(nm: number) {
  return nm * 1852;
}

export function nmToRadians(value: number) {
  const earthRadiusKm = 6371.0088;
  const radiusKm = value * 1.852;
  const radiusRadians = radiusKm / earthRadiusKm;

  return radiusRadians;
}
