import { Link, Breadcrumbs as MuiBreadcrumbs, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

export type BreadcrumbsProps = {
  pages: { name: string; href?: string }[];
};

export default function Breadcrumbs(props: BreadcrumbsProps) {
  return (
    <MuiBreadcrumbs aria-label="breadcrumb" sx={{ margin: "1rem 0" }}>
      {props.pages.map((page, index) =>
        page.href ? (
          <Link
            component={RouterLink}
            to={page.href}
            underline="hover"
            key={index}
          >
            {page.name}
          </Link>
        ) : (
          <Typography color="text.primary" key={index}>
            {page.name}
          </Typography>
        )
      )}
    </MuiBreadcrumbs>
  );
}
