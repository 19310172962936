import { Button, Stack, Typography } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import FlightCard from "../atoms/FlightCard";
import Loading from "../atoms/Loading";
import SnackbarAlert, { Alert } from "../atoms/SnackbarAlert";
import { useState } from "react";
import request, { Variables } from "graphql-request";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { graphql } from "../gql/gql";
import SaveIconButton from "../atoms/SaveIconButton";
import ShowOnMapIconButton from "../atoms/ShowOnMapIconButton";
import { FlightRecommendationsQuery } from "../gql/graphql";

const getRecommendations = graphql(/* GraphQL */ `
  query flightRecommendations($take: Int!) {
    flightRecommendations(pagination: { take: $take }) {
      departureTime
      originIcao
      destinationIcao
      airlineIcao
      airline {
        logoUrl
      }
      number
      isSaved
      aircraftType
      registration
      callsign
      distance
      origin {
        name
        iata
        icao
        country
        scenery {
          developer
          marketplace
          rating
        }
      }
      destination {
        name
        iata
        icao
        country
        scenery {
          developer
          marketplace
          rating
        }
      }
      departureGate
      arrivalGate
    }
  }
`);

const updateFlightplan = graphql(/* GraphQL */ `
  mutation updateFlightplan($flightplan: SaveFlightplanInput!) {
    saveFlightplan(flightplan: $flightplan) {
      id
      number
    }
  }
`);

export default function Recommendations() {
  const queryClient = useQueryClient();
  const { data, isLoading, error, errorUpdatedAt } = useQuery({
    queryKey: ["recommendations"],
    queryFn: async () => request("/graphql", getRecommendations, { take: 7 }),
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

  const saveFlightplanMutation = useMutation({
    mutationFn: async (variables: Variables) =>
      request("/graphql", updateFlightplan, variables),
    onSuccess: (response: { saveFlightplan: { number: string } }) => {
      // manually update query data
      queryClient.setQueryData(
        ["recommendations"],
        (old: FlightRecommendationsQuery) => {
          return {
            flightRecommendations: old.flightRecommendations.map((r) => {
              if (r.number === response.saveFlightplan.number) {
                return { ...r, isSaved: true };
              } else {
                return r;
              }
            }),
          };
        }
      );

      setMessage({
        created: new Date(),
        message: "Successfully saved for later.",
      });
    },
  });

  const [message, setMessage] = useState<Alert | null>(null);

  return (
    <Stack spacing={2}>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h6">Recommendations</Typography>

        <Button
          onClick={() => {
            queryClient.invalidateQueries({ queryKey: ["recommendations"] });
          }}
        >
          <RefreshIcon />
        </Button>
      </Stack>

      <Loading loading={isLoading} />
      <SnackbarAlert
        created={errorUpdatedAt}
        message={
          error
            ? "Error loading recommendations. Please try again later."
            : null
        }
        type="error"
        onClose={() => {}}
      />
      <SnackbarAlert
        created={message?.created}
        message={message?.message}
        type="success"
        onClose={() => setMessage(null)}
      />

      {data?.flightRecommendations.length === 0 && !isLoading && !error && (
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{ textAlign: "center", padding: "5rem" }}
        >
          You don't have any recommendations yet. Plan or save some flights to
          get started.
        </Typography>
      )}

      {data?.flightRecommendations.map((flight) => (
        <FlightCard
          key={flight.number + flight.departureTime}
          flight={flight}
          actions={
            <Stack direction="row">
              <SaveIconButton
                isSaved={flight.isSaved}
                onClick={() => {
                  saveFlightplanMutation.mutate({
                    flightplan: {
                      timeGenerated: new Date(),
                      airline: flight.airlineIcao,
                      number: flight.number,
                      originIcao: flight.originIcao,
                      destinationIcao: flight.destinationIcao,
                      alternateIcao: null,
                      aircraftType: flight.aircraftType,
                      estimatedBlockTime: null,
                      registration: flight.registration,
                      callsign: flight.callsign,
                      type: "scheduled",
                      distance: flight.distance,
                    },
                  });
                }}
              />
              <ShowOnMapIconButton
                to={`/flights?originIcao=${flight.originIcao}&destinationIcao=${flight.destinationIcao}&airlines=${flight.airlineIcao}&aircrafts=${flight.aircraftType}&registration=${flight.registration}&callsign=${flight.callsign}`}
              />
            </Stack>
          }
        />
      ))}
    </Stack>
  );
}
