import { Autocomplete, Stack, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import request from "graphql-request";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { graphql } from "../gql/gql";
import { Airline } from "../gql/graphql";
import Popper from "../atoms/Popper";

const searchAirlines = graphql(/* GraphQL */ `
  query searchAirlines($query: String!, $take: Int!) {
    searchAirlines(query: $query, pagination: { take: $take }) {
      icao
      code
      name
      logoUrl
    }
  }
`);

export type AirlineAutocompleteProps = {
  airlines: Airline[];
  label: string;
  error: boolean;
  helperText: string;
  onAirlinesSelect: (airlines: Airline[]) => void;
  selectedAirlines?: Airline[];
};

const defaultHelperText = "ICAO, IATA, or airline name";

export default function AirlineAutocomplete(props: AirlineAutocompleteProps) {
  const queryClient = useQueryClient();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const { isLoading, error, data } = useQuery({
    queryKey: ["airlines"],
    queryFn: async () =>
      request("/graphql", searchAirlines, { query: searchTerm, take: 10 }),
  });

  const debounced = useDebouncedCallback((value) => {
    queryClient.invalidateQueries({ queryKey: ["airlines"] });
  }, 300);

  let availableOptions = data?.searchAirlines ?? props.airlines;

  return (
    <Autocomplete
      id="airline_search"
      fullWidth
      multiple
      value={props.selectedAirlines}
      PopperComponent={Popper}
      filterOptions={(x) => x}
      options={availableOptions}
      isOptionEqualToValue={(option: Airline, value: Airline) => {
        return option.icao === value.icao;
      }}
      getOptionKey={(option: Airline) => option.icao}
      getOptionLabel={(option: Airline) => option.icao}
      loading={isLoading}
      renderOption={(p, option) => (
        <Stack component="li" {...p} alignItems="start">
          <Stack
            direction="row"
            spacing={2}
            justifyContent="space-between"
            sx={{ width: "100%" }}
          >
            <Typography noWrap>
              {option.icao} {option.name}
              {option.code && `/${option.code}`}
            </Typography>

            {option.logoUrl && (
              <img
                src={`/assets/airlines/${option.logoUrl}`}
                alt={option.name}
                height={16}
              />
            )}
          </Stack>
        </Stack>
      )}
      inputValue={searchTerm}
      onChange={(event: any, newValue: Airline[] | null) => {
        props.onAirlinesSelect(newValue ?? []);
      }}
      onInputChange={(event: any, newValue: string | null) => {
        setSearchTerm(newValue);
        debounced(newValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={props.label}
          aria-describedby="airline_search"
          error={props.error || !!error}
          helperText={
            props.helperText ??
            (error ? "Error loading airlines" : defaultHelperText)
          }
        />
      )}
    />
  );
}
