import { createBrowserRouter, RouterProvider } from "react-router-dom";
import DashboardPage from "./pages/DashboardPage";
import SceneriesPage from "./pages/SceneriesPage";
import NewSceneryPage from "./pages/NewSceneryPage";
import EditSceneryPage, {
  loader as sceneryLoader,
} from "./pages/EditSceneryPage";
import FlightsPage from "./pages/FlightsPage";
import FlightplansPage from "./pages/FlightplansPage";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import ProtectedRoutes, { profileLoader } from "./pages/ProtectedRoutes";
import LoginPage from "./pages/LoginPage";
import LandingPage from "./pages/LandingPage";
import ImprintPage from "./pages/ImprintPage";
import { polyfillCountryFlagEmojis } from "country-flag-emoji-polyfill";
import SettingsPage from "./pages/SettingsPage";
import SimbriefOfpPage from "./pages/SimbriefOfpPage";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "@fontsource/fira-code";
import "./simbrief.css";

polyfillCountryFlagEmojis();

export const App = () => {
  const queryClient = new QueryClient();

  let router = createBrowserRouter([
    {
      path: "/",
      element: <LandingPage />,
    },
    {
      path: "/imprint",
      element: <ImprintPage />,
    },
    {
      path: "/signin",
      element: <LoginPage />,
    },
    {
      element: <ProtectedRoutes />,
      loader: profileLoader,
      children: [
        {
          path: "/dashboard",
          element: <DashboardPage />,
          loader: profileLoader,
        },
        {
          path: "/profile/settings",
          element: <SettingsPage />,
          loader: profileLoader,
        },
        {
          path: "/flightplans",
          element: <FlightplansPage />,
        },
        {
          path: "/sceneries/:id",
          element: <EditSceneryPage />,
          loader: sceneryLoader,
        },
        {
          path: "/sceneries/new",
          element: <NewSceneryPage />,
        },
        {
          path: "/sceneries",
          element: <SceneriesPage />,
        },
        {
          path: "/flights",
          element: <FlightsPage />,
        },
        {
          path: "/simbrief",
          element: <SimbriefOfpPage />,
        },
      ],
    },
  ]);

  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
    </QueryClientProvider>
  );
};
