import styled from "@emotion/styled";
import { TableRow, Theme } from "@mui/material";

const StyledTableRow = styled(TableRow)(
  ({ theme, isHighlighted }: { theme: Theme; isHighlighted?: boolean }) => ({
    backgroundColor: isHighlighted
      ? theme.palette.primary.light
      : "transparent",

    "&:nth-of-type(odd)": {
      backgroundColor: isHighlighted
        ? theme.palette.primary.light
        : theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  })
);

export { StyledTableRow };
