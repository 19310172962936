import { IconButton, Tooltip } from "@mui/material";
import PlaceIcon from "@mui/icons-material/Place";
import { Link as RouterLink } from "react-router-dom";

export type ShowOnMapIconButtonProps = {
  to: string;
};

export default function ShowOnMapIconButton(props: ShowOnMapIconButtonProps) {
  return (
    <Tooltip title="Show on map" arrow>
      <IconButton color="primary" component={RouterLink} to={props.to}>
        <PlaceIcon />
      </IconButton>
    </Tooltip>
  );
}
