import StarIcon from "@mui/icons-material/Star";
import { useTheme } from "@mui/material";

export type RatingSmallProps = {
  rating: number;
};

export function RatingSmall(props: RatingSmallProps) {
  const theme = useTheme();
  let color = theme.palette.text.secondary;

  switch (props.rating) {
    case 1:
      color = theme.palette.error.main;
      break;
    case 2:
      color = theme.palette.error.light;
      break;
    case 3:
      color = "#CD7F32";
      break;
    case 4:
      color = "#C0C0C0";
      break;
    case 5:
      color = "#FFD700";
      break;
  }

  return <StarIcon htmlColor={color} style={{ height: "16px" }} />;
}
