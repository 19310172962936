import {
  Button,
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import FlightLandIcon from "@mui/icons-material/FlightLand";
import { Link as RouterLink } from "react-router-dom";
import { SceneryDetailsFragment } from "../gql/graphql";
import DeleteIcon from "@mui/icons-material/Delete";

export type SceneriesOptionsMenuProps = {
  scenery: SceneryDetailsFragment;
  onDelete: () => void;
};

export default function SceneriesOptionsMenu(props: SceneriesOptionsMenuProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        endIcon={<KeyboardArrowDownIcon />}
        onClick={handleClick}
        variant="contained"
      >
        Actions
      </Button>

      <Menu
        elevation={2}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem
          component={RouterLink}
          to={`/flights?originIcao=${props.scenery.icao}`}
        >
          <ListItemIcon>
            <FlightTakeoffIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Depart here</ListItemText>
        </MenuItem>

        <MenuItem
          component={RouterLink}
          to={`/flights?destinationIcao=${props.scenery.icao}`}
        >
          <ListItemIcon>
            <FlightLandIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Arrive here</ListItemText>
        </MenuItem>

        <Divider sx={{ my: 0.5 }} />

        <MenuItem
          onClick={() => {
            handleClose();
            props.onDelete();
          }}
        >
          <ListItemIcon>
            <DeleteIcon fontSize="small" color="error" />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
}
