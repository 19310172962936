import { Button, Container, Paper, Stack, Typography } from "@mui/material";
import ScrollingLayout from "../components/ScrollingLayout";
import { graphql } from "../gql";
import { useQuery } from "@tanstack/react-query";
import SnackbarAlert from "../atoms/SnackbarAlert";
import request from "graphql-request";
import Loading from "../atoms/Loading";
import Breadcrumbs from "../atoms/Breadcrumbs";
import { Link as RouterLink } from "react-router-dom";
import LocalizedDate from "../atoms/LocalizedDate";

const simbriefOfp = graphql(/* GraphQL */ `
  query simbriefOfp {
    simbriefOfp {
      ... on SimbriefOfp {
        __typename
        timeGenerated
        originIcao
        destinationIcao
        flightNumber
        airlineIcao
        planHtml
      }
      ... on SimbriefUsernameNotSetError {
        __typename
      }
      ... on SimbriefUsernameNotValidError {
        __typename
      }
    }
  }
`);

export default function SimbriefOfpPage() {
  const simbrief = useQuery({
    queryKey: ["simbriefOfp"],
    queryFn: async () => request("/graphql", simbriefOfp),
  });

  let plan = <></>;

  if (
    simbrief.data?.simbriefOfp?.__typename === "SimbriefUsernameNotSetError"
  ) {
    plan = (
      <Stack alignItems="center" spacing={4}>
        <Typography variant="body2">
          We were not able to fetch your current Simbrief OFP. Please go to
          Settings and fill in your Simbrief username.
        </Typography>

        <Button
          variant="contained"
          component={RouterLink}
          to="/profile/settings"
        >
          Settings
        </Button>
      </Stack>
    );
  } else if (
    simbrief.data?.simbriefOfp?.__typename === "SimbriefUsernameNotValidError"
  ) {
    plan = (
      <Stack alignItems="center" spacing={4}>
        <Typography variant="body2">
          We were not able to fetch your current Simbrief OFP with the name you
          provided. Please go to Settings and verify that your Simbrief username
          is set correctly.
        </Typography>

        <Button
          variant="contained"
          component={RouterLink}
          to="/profile/settings"
        >
          Settings
        </Button>
      </Stack>
    );
  } else if (simbrief.data?.simbriefOfp?.__typename === "SimbriefOfp") {
    const ofp = simbrief.data.simbriefOfp;

    plan = (
      <Stack alignItems="center" spacing={2} direction="column">
        <Typography variant="h6">
          {ofp.airlineIcao} {ofp.flightNumber}{" "}
          <LocalizedDate date={ofp.timeGenerated} /> {ofp.originIcao} -{" "}
          {ofp.destinationIcao}
        </Typography>

        <Paper
          elevation={3}
          style={{
            fontSize: "clamp(0.2rem, 2vw, 1.7rem)",
            padding: "0.2rem 0.5rem",
            margin: "0 auto",
            width: "fit-content",
          }}
        >
          <Stack
            alignItems="center"
            dangerouslySetInnerHTML={{
              __html: ofp.planHtml.replace(
                "line-height:14px;font-size:13px",
                "width: fit-content;"
              ),
            }}
          ></Stack>
        </Paper>
      </Stack>
    );
  }

  return (
    <ScrollingLayout>
      <Breadcrumbs
        pages={[
          { name: "Dashboard", href: "/dashboard" },
          { name: "Simbrief OFP" },
        ]}
      />

      <SnackbarAlert
        created={simbrief.errorUpdatedAt}
        message={simbrief.error ? "Failed to load Simbrief OFP" : null}
        type="error"
        onClose={() => null}
      />

      <Container>
        <Loading loading={simbrief.isLoading} />

        {plan}
      </Container>
    </ScrollingLayout>
  );
}
