import { Box, Button, Slide, Stack } from "@mui/material";
import { ReactElement, useState } from "react";
import CircleIcon from "@mui/icons-material/Circle";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import React from "react";
import { useInterval } from "../lib/useInterval";

export type HeroSliderProps = {
  pages: ReactElement<any, any>[];
  sx?: any;
  automaticSwitchTime?: number;
};

export default function HeroSlider(props: HeroSliderProps) {
  const [activePage, setActivePage] = useState(0);
  const [direction, setDirection] = useState<"left" | "right">("right");
  const [manual, setManual] = useState(false);

  const previous = () => {
    setDirection("right");
    setActivePage((activePage - 1 + props.pages.length) % props.pages.length);
  };

  const next = () => {
    setDirection("left");
    setActivePage((activePage + 1) % props.pages.length);
  };

  useInterval(
    next,
    props.automaticSwitchTime,
    !manual && !!props.automaticSwitchTime
  );

  const otherDirection = direction === "left" ? "right" : "left";

  return (
    <>
      <Stack
        sx={{ ...props.sx, position: "relative", overflow: "hidden" }}
        alignItems="stretch"
      >
        {props.pages.map((page, index) => {
          const SlidePage = React.forwardRef((props, ref) => (
            <Box
              sx={{
                position: "absolute",
                height: "100%",
                width: "100%",
                overflow: "hidden",
              }}
              ref={ref}
              {...props}
            >
              {page}
            </Box>
          ));

          return (
            <Slide
              direction={activePage === index ? direction : otherDirection}
              in={activePage === index}
              appear={false}
              mountOnEnter
              unmountOnExit
              timeout={1000}
              key={index}
            >
              <SlidePage />
            </Slide>
          );
        })}

        <Stack
          sx={{
            height: "100%",
            width: "5rem",
            position: "absolute",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            left: 0,
            color: "white",
          }}
          onClick={() => {
            setManual(true);
            previous();
          }}
        >
          <ArrowBackIosIcon />
        </Stack>

        <Stack
          sx={{
            height: "100%",
            width: "5rem",
            position: "absolute",
            justifyContent: "center",
            alignItems: "center",
            right: 0,
            cursor: "pointer",
            color: "white",
          }}
          onClick={() => {
            setManual(true);
            next();
          }}
        >
          <ArrowForwardIosIcon />
        </Stack>
      </Stack>

      <Stack direction="row" justifyContent="space-around">
        <Stack direction="row">
          {props.pages.map((page, index) => (
            <Button
              key={index}
              variant="text"
              size="small"
              sx={{
                padding: "0.5rem 0",
                minWidth: "32px",
                color: index === activePage ? "default" : "inherit",
              }}
              onClick={() => {
                setManual(true);
                setActivePage(index);
              }}
            >
              <CircleIcon sx={{ fontSize: "0.5rem" }} />
            </Button>
          ))}
        </Stack>
      </Stack>
    </>
  );
}
