// @ts-ignore
import { MapContainer, TileLayer, useMap, useMapEvents } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-defaulticon-compatibility";
import "leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.css";
import { LatLngBounds } from "leaflet";
import { CSSProperties, ReactNode, useEffect } from "react";
import { useTheme } from "@mui/material/styles";

type MapEventsComponentProps = {
  onZoomEnd: (zoom: number, bounds: LatLngBounds) => void;
  onMoveEnd: (zoom: number, bounds: LatLngBounds) => void;
  bounds: LatLngBounds;
};

function MapEventsComponent({
  onZoomEnd,
  onMoveEnd,
  bounds,
}: MapEventsComponentProps) {
  const map = useMap();

  useEffect(() => {
    map.fitBounds(bounds);
  }, [map, bounds]);

  useMapEvents({
    zoomend: (event) => {
      onZoomEnd(event.target.getZoom(), event.target.getBounds());
    },
    moveend: (event) => {
      onMoveEnd(event.target.getZoom(), event.target.getBounds());
    },
  });

  return null;
}

type MapProps = {
  children?: ReactNode;
  onMoveEnd?: (zoom: number, bounds: LatLngBounds) => void;
  bounds: LatLngBounds;
  zoom: number;
  sx?: CSSProperties;
};

export default function Map(props: MapProps) {
  const theme = useTheme();

  return (
    <MapContainer
      bounds={props.bounds}
      maxBounds={
        new LatLngBounds([
          [-180, -180],
          [180, 180],
        ])
      }
      zoom={props.zoom}
      scrollWheelZoom={true}
      style={{
        ...props.sx,
        height: "100%",
        width: "100%",
        background: "transparent",
      }}
    >
      <MapEventsComponent
        onZoomEnd={props.onMoveEnd}
        onMoveEnd={props.onMoveEnd}
        bounds={props.bounds}
      />
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />

      {props.children}
      <style>{`
        .leaflet-layer,
        .leaflet-control-zoom-in,
        .leaflet-control-zoom-out,
        .leaflet-control-attribution {
          ${theme.map.style}
        }
        .leaflet-attribution-flag {
          ${theme.map.style}
        }
        .leaflet-div-icon {
          background: none;
          border: none;
        }
        .leaflet-tooltip {
          background-color: ${theme.palette.background.paper};
          color: ${theme.palette.text.primary};
          border: 0;
          box-shadow: none;
        }
        .leaflet-tooltip-bottom::before {
          border-bottom-color: ${theme.palette.background.paper};
        }
        .leaflet-tooltip-top:before {
          border-top-color: ${theme.palette.background.paper};
        }
        .leaflet-tooltip-left::before {
          border-left-color: ${theme.palette.background.paper};
        }
        .leaflet-tooltip-right::before {
          border-right-color: ${theme.palette.background.paper};
        }
      `}</style>
    </MapContainer>
  );
}
