import { Stack, Switch } from "@mui/material";
import NightlightIcon from "@mui/icons-material/Nightlight";

export type ThemeSwitchProps = {
  darkMode: boolean;
  setDarkMode: (darkMode: boolean) => void;
};

export default function ThemeSwitch(props: ThemeSwitchProps) {
  return (
    <Stack direction="row" alignItems="center">
      <NightlightIcon />
      <Switch
        onChange={() => props.setDarkMode(!props.darkMode)}
        checked={props.darkMode}
      />
    </Stack>
  );
}
