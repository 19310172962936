import { CircularProgress, Stack } from "@mui/material";

export default function Loading(props: { loading: boolean }) {
  if (!props.loading) {
    return null;
  }

  return (
    <Stack alignItems="center" sx={{ padding: "5rem 0" }}>
      <CircularProgress />
    </Stack>
  );
}
