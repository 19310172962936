import {
  Button,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from "@mui/material";
import { StyledTableRow } from "./Table";
import SimbriefPlanButton from "./SimbriefPlanButton";
import DeleteIcon from "@mui/icons-material/Delete";
import CountryFlag from "./CountryFlag";
import NotAvailableText from "./NotAvailableText";
import ColoredChip from "./ColoredChip";
import PhotoLink from "./PhotoLink";

export type FlightsTableFlightFragment = {
  id: string;
  number: string;
  airlineIcao: string;
  registration?: string;
  callsign?: string;
  distance: number;
  type: string;
  origin: {
    icao: string;
    iata?: string;
    country: string;
    name: string;
    latlng: {
      lat: number;
      lng: number;
    };
  };
  destination: {
    icao: string;
    iata?: string;
    country: string;
    name: string;
    latlng: {
      lat: number;
      lng: number;
    };
  };
  aircraftType: string;
  departureGate?: string;
  arrivalGate?: string;
};

export type AirportFragment = {
  icao: string;
  iata?: string;
  name: string;
  country: string;
  latlng: {
    lat: number;
    lng: number;
  };
  scenery?: {
    developer: string;
    marketplace: string;
  } | null;
};

export type FlightsTableProps = {
  flights: FlightsTableFlightFragment[];
  onDeleteFlight: (flight: FlightsTableFlightFragment) => void;
  onOriginSelect?: (airport?: AirportFragment) => void;
  onDestinationSelect?: (airport?: AirportFragment) => void;
  canBeDeleted: (flight: FlightsTableFlightFragment) => boolean;
};

export default function FlightsTable(props: FlightsTableProps) {
  const theme = useTheme();

  return (
    (props.flights.length > 0 && (
      <TableContainer component={Paper} sx={{ overflowY: "auto" }}>
        <Table aria-label="sceneries" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Flight</TableCell>
              <TableCell>Depart</TableCell>
              <TableCell>Arrive</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Airframe</TableCell>
              <TableCell>Distance</TableCell>
              <TableCell>Gates</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.flights.map((row) => (
              <StyledTableRow theme={theme} key={row.id}>
                <TableCell component="th" scope="row">
                  {row.airlineIcao} {row.number}
                </TableCell>
                <TableCell>
                  <Button
                    variant="text"
                    size="small"
                    onClick={() => props.onOriginSelect(row.origin)}
                  >
                    <Stack direction="row" alignItems="center" spacing={0.5}>
                      <p>
                        {row.origin.icao}/{row.origin.iata}
                      </p>
                      <CountryFlag country={row.origin.country} />
                    </Stack>
                  </Button>
                </TableCell>
                <TableCell>
                  <Button
                    variant="text"
                    size="small"
                    onClick={() => props.onDestinationSelect(row.destination)}
                  >
                    <Stack direction="row" alignItems="center" spacing={0.5}>
                      <p>
                        {row.destination.icao}/{row.destination.iata}
                      </p>
                      <CountryFlag country={row.destination.country} />
                    </Stack>
                  </Button>
                </TableCell>
                <TableCell>
                  <ColoredChip label={row.aircraftType} />
                </TableCell>
                <TableCell>
                  <PhotoLink registration={row.registration} />
                </TableCell>
                <TableCell sx={{ whiteSpace: "nowrap" }}>
                  {row.distance} NM
                </TableCell>
                <TableCell sx={{ textWrap: "nowrap" }}>
                  {row.departureGate ? (
                    <ColoredChip label={row.departureGate} />
                  ) : (
                    <NotAvailableText />
                  )}{" "}
                  {row.arrivalGate ? (
                    <ColoredChip label={row.arrivalGate} />
                  ) : (
                    <NotAvailableText />
                  )}
                </TableCell>
                <TableCell sx={{ whiteSpace: "nowrap" }}>
                  <SimbriefPlanButton flight={row} />

                  {props.canBeDeleted(row) && (
                    <Button
                      variant="text"
                      color="error"
                      size="small"
                      onClick={() => {
                        props.onDeleteFlight(row);
                      }}
                    >
                      <DeleteIcon />
                    </Button>
                  )}
                </TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    )) ??
    null
  );
}
