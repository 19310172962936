import { Typography } from "@mui/material";
import SceneryForm from "../components/SceneryForm";
import SnackbarAlert, { Alert } from "../atoms/SnackbarAlert";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLoaderData } from "react-router-dom";
import { Scenery } from "../../models/scenery";
import request, { Variables } from "graphql-request";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { graphql } from "../gql/gql";
import ScrollingLayout from "../components/ScrollingLayout";
import Breadcrumbs from "../atoms/Breadcrumbs";

const getScenery = graphql(/* GraphQL */ `
  query scenery($id: ID!) {
    scenery(id: $id) {
      id
      icao
      platform
      developer
      marketplace
      airport {
        icao
        iata
        name
        country
      }
    }
  }
`);

const updateScenery = graphql(/* GraphQL */ `
  mutation updateScenery($scenery: UpdateSceneryInput!) {
    updateScenery(scenery: $scenery) {
      id
    }
  }
`);

export async function loader({ params }) {
  const scenery = await request("/graphql", getScenery, { id: params.id });

  return scenery;
}

export default function EditSceneryPage() {
  const { scenery } = useLoaderData() as { scenery: Scenery };
  const navigate = useNavigate();
  const [error, setError] = useState<Alert | null>(
    null
  );
  const queryClient = useQueryClient();

  const updateSceneryMutation = useMutation({
    mutationFn: async (variables: Variables) =>
      request("/graphql", updateScenery, variables),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["sceneries"] });
      navigate("/sceneries");
    },
    onError: (error) => {
      setError({ created: new Date(), message: error.message });
    },
  });

  return (
    <ScrollingLayout>
      <Breadcrumbs
        pages={[
          { name: "Dashboard", href: "/dashboard" },
          { name: "Sceneries", href: "/sceneries" },
          { name: `Edit ${scenery.icao}` },
        ]}
      />

      <Typography variant="h5" gutterBottom>
        Edit Scenery
      </Typography>

      <SceneryForm
        scenery={scenery}
        onCancel={() => navigate(-1)}
        onSubmit={(scenery: Scenery) => {
          updateSceneryMutation.mutate({
            scenery: {
              id: scenery.id,
              icao: scenery.icao,
              platform: scenery.platform,
              developer: scenery.developer,
              marketplace: scenery.marketplace,
            },
          });
        }}
      />

      <SnackbarAlert
        created={error?.created}
        message={error?.message}
        onClose={() => setError(null)}
        type={"error"}
      />
    </ScrollingLayout>
  );
}
