import { Typography } from "@mui/material";
import { useState } from "react";
import { Scenery } from "../../models/scenery";
import SceneryForm from "../components/SceneryForm";
import SnackbarAlert, { Alert } from "../atoms/SnackbarAlert";
import { useNavigate } from "react-router-dom";
import request, { Variables } from "graphql-request";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { graphql } from "../gql/gql";
import ScrollingLayout from "../components/ScrollingLayout";
import Breadcrumbs from "../atoms/Breadcrumbs";

const createScenery = graphql(/* GraphQL */ `
  mutation createScenery($scenery: CreateSceneryInput!) {
    createScenery(scenery: $scenery) {
      id
    }
  }
`);

export default function NewSceneryPage() {
  const navigate = useNavigate();
  const [error, setError] = useState<Alert | null>(null);
  const queryClient = useQueryClient();

  const createSceneryMutation = useMutation({
    mutationFn: async (variables: Variables) =>
      request("/graphql", createScenery, variables),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["sceneries"] });
      navigate("/sceneries");
    },
    onError: (error) => {
      setError({
        created: new Date(),
        message: "Failed to create scenery, please try again later.",
      });
    },
  });

  return (
    <ScrollingLayout>
      <Breadcrumbs
        pages={[
          { name: "Dashboard", href: "/dashboard" },
          { name: "Sceneries", href: "/sceneries" },
          { name: "New" },
        ]}
      />

      <Typography variant="h5" gutterBottom>
        Add Scenery
      </Typography>

      <SceneryForm
        onCancel={() => navigate(-1)}
        onSubmit={(scenery: Scenery) => {
          createSceneryMutation.mutate({
            scenery: {
              icao: scenery.icao,
              platform: scenery.platform,
              developer: scenery.developer,
              marketplace: scenery.marketplace,
            },
          });
        }}
      />

      <SnackbarAlert
        created={error?.created}
        message={error?.message}
        onClose={() => setError(null)}
        type="error"
      />
    </ScrollingLayout>
  );
}
