// @ts-ignore
import { Marker } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-defaulticon-compatibility";
import "leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.css";
import { LatLngBounds, DivIcon, PointExpression } from "leaflet";
import { renderToString } from "react-dom/server";
import Map from "../atoms/Map";
import AirportTooltip from "../atoms/AirportTooltip";
import { SceneryDetailsFragment } from "../gql/graphql";
import { RatingSmall } from "../atoms/RatingSmall";

const defaultBounds: LatLngBounds = new LatLngBounds([
  [50, -5],
  [-5, 0],
]);

type Scenery = SceneryDetailsFragment;

type SceneryMapProps = {
  sceneries: Scenery[];
};

export default function SceneryMap(props: SceneryMapProps) {
  const zoom = 1;
  let bounds = defaultBounds;

  if (props.sceneries.length > 0) {
    bounds = props.sceneries.reduce((acc: LatLngBounds, scenery: Scenery) => {
      if (!acc) {
        return new LatLngBounds(
          [scenery.airport.latlng.lat, scenery.airport.latlng.lng],
          [scenery.airport.latlng.lat, scenery.airport.latlng.lng]
        );
      } else {
        return acc.extend([
          scenery.airport.latlng.lat,
          scenery.airport.latlng.lng,
        ]);
      }
    }, null);
  }

  if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
    bounds = defaultBounds;
  }

  return (
    <Map onMoveEnd={() => {}} zoom={zoom} bounds={bounds ?? defaultBounds}>
      {props.sceneries.map((scenery) => {
        let iconSize: PointExpression = [32, 32];
        let icon = <RatingSmall rating={scenery.rating} />;

        const divIcon = new DivIcon({
          html: renderToString(icon),
          iconSize: iconSize,
          iconAnchor: iconSize.map((x) => x / 2) as PointExpression,
        });

        return (
          <Marker
            position={[scenery.airport.latlng.lat, scenery.airport.latlng.lng]}
            icon={divIcon}
            key={scenery.id}
          >
            <AirportTooltip airport={scenery.airport} />
          </Marker>
        );
      })}

      <style>{`
        .leaflet-div-icon {
          background: none;
          border: none;
        }
      `}</style>
    </Map>
  );
}
