import { Button, Link, Stack, Tooltip } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

export default function SimbriefPlanButton({
  flight,
}: {
  flight: {
    airlineIcao: string;
    number?: string;
    origin: { icao: string };
    destination: { icao: string };
    aircraftType: string;
    callsign?: string;
    registration?: string;
  };
}) {
  const simbriefUrl =
    `https://dispatch.simbrief.com/options/custom?` +
    `airline=${flight.airlineIcao}&fltnum=${flight.number}&orig=${flight.origin.icao}&dest=${flight.destination.icao}` +
    `&altn=&basetype=${flight.aircraftType}&ac_order=registration&type=&climb=&cruisemode=&cruisesub=&descent=` +
    `&fuelfactor=1&callsign=${flight.callsign}&reg=${flight.registration}`;

  return (
    <Tooltip title="Plan now using Simbrief" arrow>
      <Button LinkComponent={Link} href={simbriefUrl} target="_blank">
        <Stack direction="row" alignItems="center" spacing={1}>
          <p>Plan now</p>
          <OpenInNewIcon />
        </Stack>
      </Button>
    </Tooltip>
  );
}
