import {
  Card,
  CardActions,
  CardContent,
  Grid,
  IconButton,
  Rating,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { SceneryPackage } from "../gql/graphql";
import { SceneryDataDisplayProps } from "../pages/SceneriesPage";
import CountryFlag from "../atoms/CountryFlag";
import EditIcon from "@mui/icons-material/Edit";
import { Link as RouterLink } from "react-router-dom";
import ColoredChip from "../atoms/ColoredChip";
import SceneriesOptionsMenu from "../atoms/SceneriesOptionsMenu";

export default function SceneryCardGrid(props: SceneryDataDisplayProps) {
  return (
    <Stack sx={{ padding: "1rem", width: "100%" }}>
      <Grid container spacing={2}>
        {props.sceneries.map((scenery) => (
          <Grid item sm={12} md={6} lg={4} key={scenery.id}>
            <Card>
              <CardContent>
                <Stack
                  direction="row"
                  alignItems="start"
                  justifyContent="space-between"
                  spacing={1}
                >
                  <Stack width="100%">
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      spacing={1}
                      width="100%"
                    >
                      <Typography color="text.secondary" fontSize="0.8rem">
                        {scenery.icao}
                      </Typography>

                      {scenery.package && (
                        <ColoredChip
                          onDelete={() => {
                            props.onSceneryPackageRemove(
                              scenery.package as SceneryPackage
                            );
                          }}
                          label={scenery.package.name}
                          size="small"
                        />
                      )}
                    </Stack>

                    <Typography variant="h6" gutterBottom>
                      {scenery.airport.name}/{scenery.airport.iata}{" "}
                      <CountryFlag country={scenery.airport.country} />
                    </Typography>
                  </Stack>
                </Stack>

                <Grid container>
                  <Grid item xs={6} md={6}>
                    <strong>Platform:</strong>
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <ColoredChip label={scenery.platform} size="small" />
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <strong>Developer:</strong>
                  </Grid>
                  <Grid item xs={6} md={6}>
                    {scenery.developer}
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <strong>Marketplace:</strong>
                  </Grid>
                  <Grid item xs={6} md={6}>
                    {scenery.marketplace}
                  </Grid>
                </Grid>
              </CardContent>
              <CardActions>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  width="100%"
                >
                  <Tooltip title="Rating" arrow>
                    <Rating
                      value={scenery.rating}
                      onChange={(_event: any, newValue: number) => {
                        const newRating = newValue ?? 0;
                        props.onRatingChange(scenery, newRating);
                      }}
                    />
                  </Tooltip>

                  <Stack direction="row">
                    <IconButton
                      color="primary"
                      size="small"
                      component={RouterLink}
                      to={`/sceneries/${scenery.id}`}
                    >
                      <EditIcon />
                    </IconButton>

                    <SceneriesOptionsMenu
                      scenery={scenery}
                      onDelete={() => {
                        props.onSceneryDelete(scenery);
                      }}
                    />
                  </Stack>
                </Stack>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Stack>
  );
}
