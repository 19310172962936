import { Avatar, Button, Link, Menu, MenuItem } from "@mui/material";
import { User } from "../gql/graphql";
import { useState } from "react";

export default function LoginProfile(props: { user: User | null }) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button id="avatar" onClick={handleClick}>
        <Avatar alt={props.user?.name} src={props.user?.picture} />
      </Button>
      <Menu
        id="user-menu"
        aria-labelledby="avatar"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem role="none">
          <Button
            LinkComponent={Link}
            variant="text"
            role="menuitem"
            href="/dashboard"
          >
            Dashboard
          </Button>
        </MenuItem>
        <MenuItem role="none">
          <Button
            LinkComponent={Link}
            variant="text"
            role="menuitem"
            href="/profile/settings"
          >
            Settings
          </Button>
        </MenuItem>
        <MenuItem role="none">
          <Button
            LinkComponent={Link}
            variant="text"
            color="error"
            role="menuitem"
            href="/logout"
          >
            Logout
          </Button>
        </MenuItem>
      </Menu>
    </>
  );
}
