import { Button, Link } from "@mui/material";
import NotAvailableText from "./NotAvailableText";

export default function PhotoLink(props: { registration: string }) {
  if (!props.registration) {
    return <NotAvailableText />;
  }

  return (
    <Button
      variant="text"
      size="small"
      LinkComponent={Link}
      href={`https://www.planespotters.net/search?q=${props.registration}`}
      target="_blank"
    >
      {props.registration}
    </Button>
  );
}
