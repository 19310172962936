import {
  Box,
  Button,
  Stack,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Airport } from "../../models/airport";
import FlightsMap from "../components/FlightsMap";
import SnackbarAlert from "../atoms/SnackbarAlert";
import { useEffect, useState } from "react";
import FlightsTable from "../atoms/FlightsTable";
import FlightsFilter from "../components/FlightsFilter";
import request from "graphql-request";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { graphql } from "../gql/gql";
import ConfirmDialog from "../atoms/ConfirmDialog";
import Breadcrumbs from "../atoms/Breadcrumbs";
import { FlightplanType } from "../gql/graphql";
import Loading from "../atoms/Loading";
import ScrollingLayout from "../components/ScrollingLayout";
import Pagination from "../atoms/Pagination";
import { FlightFilterInput, filterToURL, urlToFilter } from "../FlightFilter";
import FlightsCardGrid from "../components/FlightsCardGrid";
import { useSearchParams } from "react-router-dom";

const getFlightplans = graphql(/* GraphQL */ `
  query flightplans(
    $filter: FlightplanFilterInput!
    $pagination: PaginationOptions!
  ) {
    flightplans(filter: $filter, pagination: $pagination) {
      take
      skip
      total
      results {
        id
        number
        timeGenerated
        departureTime
        type
        airlineIcao
        airline {
          logoUrl
        }
        aircraftType
        registration
        callsign
        distance
        origin {
          icao
          iata
          name
          country
          latlng {
            lat
            lng
          }
          scenery {
            developer
            marketplace
            rating
          }
        }
        destination {
          icao
          iata
          name
          country
          latlng {
            lat
            lng
          }
          scenery {
            developer
            marketplace
            rating
          }
        }
        departureGate
        arrivalGate
      }
    }
  }
`);

const getAirport = graphql(/* GraphQL */ `
  query getAirport($icao: ID!) {
    airport(icao: $icao) {
      icao
      iata
      name
      type
      country
      latlng {
        lat
        lng
      }
      scenery {
        platform
        developer
        marketplace
        rating
      }
    }
  }
`);

const deleteFlightplan = graphql(/* GraphQL */ `
  mutation deleteFlightplan($id: ID!) {
    deleteFlightplan(id: $id)
  }
`);

type FlightPlanToDelete = {
  id: string;
  number?: string;
  origin: { icao: string };
  destination: { icao: string };
};

export default function FlightplansPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const theme = useTheme();
  const take = 25;
  const breakpointTable = useMediaQuery(theme.breakpoints.up("lg"));
  const [type, setType] = useState<"saved" | "simbrief">(
    (searchParams.has("type") ? searchParams.get("type") : "saved") as
      | "saved"
      | "simbrief"
  );
  const [skip, setSkip] = useState(0);
  const [flightplanToDelete, setFlightplanToDelete] =
    useState<FlightPlanToDelete | null>(null);
  const queryClient = useQueryClient();
  const [filter, setFilter] = useState<FlightFilterInput>(
    urlToFilter(searchParams)
  );

  const deleteFlightplanMutation = useMutation({
    mutationFn: async () =>
      request("/graphql", deleteFlightplan, { id: flightplanToDelete?.id }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["flightplans", filter, type, skip],
      });
      setFlightplanToDelete(null);
    },
  });

  const origin = useQuery({
    queryKey: ["origin", filter.originIcao],
    queryFn: async () =>
      request("/graphql", getAirport, {
        icao: filter.originIcao,
      }),
    enabled: !!filter.originIcao,
  });

  const destination = useQuery({
    queryKey: ["destination", filter.destinationIcao],
    queryFn: async () =>
      request("/graphql", getAirport, {
        icao: filter.destinationIcao,
      }),
    enabled: !!filter.destinationIcao,
  });

  const flightplans = useQuery({
    queryKey: ["flightplans", filter, type, skip],
    queryFn: async () =>
      request("/graphql", getFlightplans, {
        filter: {
          type:
            type === "saved"
              ? FlightplanType.Scheduled
              : FlightplanType.Simbrief,
          originIcao: filter.originIcao,
          destinationIcao: filter.destinationIcao,
          aircraftIcao: filter.aircrafts?.map((a) => a.id),
          airlineIcao: filter.airlines?.map((a) => a.icao),
          distance: filter.distance,
        },
        pagination: { take, skip },
      }),
  });

  const originAirports =
    flightplans?.data?.flightplans?.results?.map((f) => f.origin) ?? [];
  const destinationAirports =
    flightplans?.data?.flightplans?.results?.map((f) => f.destination) ?? [];

  const airports = new Set(originAirports.concat(destinationAirports));

  const resetFilters = () => {
    setFilter({ distance: { min: 0 } });
  };

  useEffect(() => {
    const params = filterToURL(filter);
    if (type !== "saved") {
      params.append("type", type);
    }

    setSearchParams(params);
    setSkip(0);
  }, [setSearchParams, filter, type]);

  return (
    <ScrollingLayout>
      <SnackbarAlert
        created={flightplans.errorUpdatedAt}
        message={flightplans.error ? "Error loading flightplans." : null}
        type="error"
        onClose={() => null}
      />

      {flightplanToDelete && (
        <ConfirmDialog
          title="Delete Flightplan"
          content={`Are you sure you want to delete the flightplan for ${flightplanToDelete.number} (${flightplanToDelete.origin.icao} - ${flightplanToDelete.destination.icao})?`}
          onConfirm={() => {
            deleteFlightplanMutation.mutate();
          }}
          onClose={function (): void {
            setFlightplanToDelete(null);
          }}
          open={!!flightplanToDelete}
        />
      )}

      <Stack direction="column">
        <Breadcrumbs
          pages={[
            { name: "Dashboard", href: "/dashboard" },
            { name: "Saved Flights" },
          ]}
        />

        <Stack direction="column" paddingBottom={2}>
          <FlightsFilter
            airports={Array.from(airports)}
            origin={origin.data?.airport}
            destination={destination.data?.airport}
            airlinesSelected={filter.airlines}
            aircraftsSelected={filter.aircrafts}
            distanceSelected={filter.distance}
            onSwapOriginDestination={() => {
              setFilter({
                ...filter,
                originIcao: filter.destinationIcao,
                destinationIcao: filter.originIcao,
              });
            }}
            onOriginChange={(origin) =>
              setFilter({ ...filter, originIcao: origin?.icao })
            }
            onDestinationChange={(destination) =>
              setFilter({ ...filter, destinationIcao: destination?.icao })
            }
            onAircraftsChange={(aircrafts) =>
              setFilter({ ...filter, aircrafts })
            }
            onAirlinesChange={(airlines) => setFilter({ ...filter, airlines })}
            onDistanceChange={(distance) => setFilter({ ...filter, distance })}
          />
        </Stack>

        <Box height={300}>
          <FlightsMap
            airports={Array.from(airports) as Airport[]}
            onMoveEnd={(zoom, bounds) => null}
            onRouteSelect={(origin, destination) => {
              setFilter({
                ...filter,
                originIcao: origin.icao,
                destinationIcao: destination.icao,
              });
            }}
            onDestinationSelect={(airport) =>
              setFilter({ ...filter, destinationIcao: airport.icao })
            }
            onOriginSelect={(airport) =>
              setFilter({ ...filter, originIcao: airport.icao })
            }
            flights={flightplans.data?.flightplans?.results ?? []}
            origin={origin.data?.airport}
            destination={destination.data?.airport}
          />
        </Box>
      </Stack>

      <Stack direction="column" spacing={2}>
        <Stack direction="column">
          <Tabs
            value={type}
            onChange={() => setType(type === "saved" ? "simbrief" : "saved")}
            aria-label="flightplan type"
          >
            <Tab label="Saved" value={"saved"} />
            <Tab label="Simbrief" value={"simbrief"} />
          </Tabs>

          <Stack direction="column">
            <Loading loading={flightplans?.isLoading} />

            {flightplans.data?.flightplans?.results?.length === 0 ? (
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ textAlign: "center", padding: "1rem" }}
              >
                Couldn't find any saved flights.{" "}
                <Button variant="text" onClick={resetFilters}>
                  Reset filters
                </Button>{" "}
                to find any.
              </Typography>
            ) : (
              <Stack direction="column" spacing={2} alignItems="center">
                {breakpointTable ? (
                  <FlightsTable
                    flights={flightplans.data?.flightplans.results ?? []}
                    onOriginSelect={(airport) =>
                      setFilter({ ...filter, originIcao: airport.icao })
                    }
                    onDestinationSelect={(airport) =>
                      setFilter({ ...filter, destinationIcao: airport.icao })
                    }
                    onDeleteFlight={(flight) => setFlightplanToDelete(flight)}
                    canBeDeleted={(flight) =>
                      flight.type !== FlightplanType.Simbrief
                    }
                  />
                ) : (
                  <FlightsCardGrid
                    enableSave={false}
                    enableDelete={true}
                    canBeDeleted={(flight) =>
                      flight.type !== FlightplanType.Simbrief
                    }
                    onDeleteFlight={(flight) => setFlightplanToDelete(flight)}
                    onOriginChange={function (icao?: string) {
                      setFilter({ ...filter, originIcao: icao });
                    }}
                    onDestinationChange={function (icao?: string) {
                      setFilter({ ...filter, destinationIcao: icao });
                    }}
                    onResetFilters={function () {
                      resetFilters();
                    }}
                    flights={flightplans.data?.flightplans.results ?? []}
                    filter={filter}
                  />
                )}

                <Pagination
                  take={flightplans.data?.flightplans?.take}
                  skip={flightplans.data?.flightplans?.skip}
                  total={flightplans.data?.flightplans?.total}
                  onChange={(skip) => setSkip(skip)}
                />
              </Stack>
            )}
          </Stack>
        </Stack>
      </Stack>
    </ScrollingLayout>
  );
}
