import { Autocomplete, TextField, Typography } from "@mui/material";
import request from "graphql-request";
import { useQuery } from "@tanstack/react-query";
import { graphql } from "../gql/gql";
import Popper from "../atoms/Popper";

const getAircrafts = graphql(/* GraphQL */ `
  query aircrafts($take: Int!) {
    aircrafts(pagination: { take: $take }) {
      id
      name
    }
  }
`);

export type AircraftAutocompleteProps = {
  label: string;
  error: boolean;
  helperText: string;
  onAircraftsSelected: (aircrafts: Aircraft[]) => void;
  selectedAircrafts: Aircraft[] | null;
};

const defaultHelperText = "ICAO,or aircraft type name";

type Aircraft = {
  id: string;
  name: string;
};

export default function AircraftAutocomplete(props: AircraftAutocompleteProps) {
  const aircrafts = useQuery({
    queryKey: ["aircrafts"],
    queryFn: async () => request("/graphql", getAircrafts, { take: 1000 }),
  });

  return (
    <Autocomplete
      id="aircraft_search"
      disablePortal
      value={props.selectedAircrafts ?? []}
      PopperComponent={Popper}
      multiple
      sx={{ minWidth: 200 }}
      options={aircrafts?.data?.aircrafts ?? []}
      getOptionKey={(option: Aircraft) => option.id}
      getOptionLabel={(option: Aircraft) => `${option.id}`}
      isOptionEqualToValue={(option: Aircraft, value: Aircraft) => {
        return option.id === value.id;
      }}
      loading={aircrafts.isLoading}
      onChange={(event: any, newValue: Aircraft[] | null) => {
        props.onAircraftsSelected(newValue);
      }}
      renderOption={(props, option, { selected }) => {
        return (
          <li {...props}>
            <Typography noWrap>{`${option.id} - ${option.name}`}</Typography>
          </li>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={props.label}
          aria-describedby="aircraft_search"
          error={props.error || !!aircrafts.error}
          helperText={
            props.helperText ??
            (aircrafts.error ? "Error loading aircrafts" : defaultHelperText)
          }
        />
      )}
    />
  );
}
