import { Button, Container, Link, Stack, Typography } from "@mui/material";
import ScrollingLayout from "../components/ScrollingLayout";
import HeroSlider from "../components/HeroSlider";
import { ReactNode } from "react";

export function SlideWithBackgroundImage(props: {
  image: string;
  children: ReactNode;
}) {
  return (
    <Stack
      sx={{
        backgroundImage: `url(${props.image})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        height: "100%",
      }}
    >
      <Stack
        sx={{
          height: "100%",
          padding: "2rem",
          backgroundImage:
            "linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.8))",
          color: "white",
          justifyContent: "end",
        }}
      >
        <Container>{props.children}</Container>
      </Stack>
    </Stack>
  );
}

export default function LandingPage() {
  return (
    <ScrollingLayout fullwidth showDrawer={false}>
      <link rel="preload" as="image" href="/assets/hero_scenery.jpg"></link>
      <link
        rel="preload"
        as="image"
        href="/assets/hero_recommendations.jpg"
      ></link>
      <link rel="preload" as="image" href="/assets/hero_easy.jpg"></link>

      <HeroSlider
        sx={{ height: "30rem" }}
        automaticSwitchTime={10000}
        pages={[
          <SlideWithBackgroundImage image={"/assets/hero_airport.png"}>
            <Typography variant="h2">Simulated Flight. Real Ops.</Typography>
            <Typography variant="h5">
              FlySimRoutes is the easiest way to get real-life flights into the
              simulator of your choice. Stop searching flight tracker apps and
              start flying!
            </Typography>
          </SlideWithBackgroundImage>,
          <SlideWithBackgroundImage image={"/assets/hero_scenery.jpg"}>
            <Typography variant="h2">Up-To-Date Dataset</Typography>
            <Typography variant="h5">
              Choose from real-life routes to over 3500 destinations. We update
              our database daily to provide you with the most recent flights.
            </Typography>
          </SlideWithBackgroundImage>,
          <SlideWithBackgroundImage image={"/assets/hero_recommendations.jpg"}>
            <Typography variant="h2">Recommendations</Typography>
            <Typography variant="h5">
              Based on your recent flights, find interesting new destinations
              with no effort. FlySimRoutes will recommend you the best routes to
              choose from based on your preferred aircaft. You can even choose
              from short-haul to long-haul flights.
            </Typography>
          </SlideWithBackgroundImage>,
          <SlideWithBackgroundImage image={"/assets/hero_easy.jpg"}>
            <Typography variant="h2">Easy as 1-2-3</Typography>
            <Typography variant="h5">
              Find interesting flights - plan through Simbrief - enjoy! It's
              that easy with FlySimRoutes. Your next most interesting flight
              experience is just a few clicks away.
            </Typography>
          </SlideWithBackgroundImage>,
        ]}
      />

      <Container sx={{ marginTop: "2rem", marginBottom: "2rem" }}>
        <Typography variant="h3" mb={2}>
          Experience Real-Life Flights in your Simulator today!
        </Typography>

        <Typography mt={1}>
          Whether you want to plan flights for your virtual airline, are curious
          about which carriers operate a certain route or just searching for a
          new interesting route to fly, FlySimRoutes is the perfect planning
          tool for your next flight!
        </Typography>

        <Typography mt={1}>
          For most of us flight simmers, it's most interesting to do flights how
          they are done in real life: the same way an operator does them from A
          to B with that exact aircraft type, registration, and ATC callsign.
          FlySimroutes gets you there - without the effort of gathering intel
          and scouring for flights on flight tracking apps yourself.
        </Typography>

        <Typography variant="h4" mt={5}>
          Why do you need a tool to plan flights?
        </Typography>

        <Typography mt={1}>
          Let's be honest here: who hasn't spent hours and hours on flight
          tracking apps and searched for interested flights to operate in their
          simulator? Don't you think there should be an easier way to do this?
        </Typography>

        <Typography mt={1}>
          Most of those tools are not designed to quickly find interesting
          flights but rather to track single flights or lookup single airports
          instead. FlySimRoutes fills that gap and provides a tool to easily
          filter and search through flight data to find the most interesting one
          to you. You want to purchase a new scenery? Look up the flights going
          in and out of it, maybe there is a route operated to another scenery
          you already own!
        </Typography>

        <Typography variant="h4" mt={5}>
          What makes FlySimRoutes different?
        </Typography>

        <ul>
          <li>
            <b>Unified data</b>: FlySimRoutes uses an extensive dataset of
            real-life flights operated by all major carriers which gets updated
            daily. You can choose from thousands of IRL flights today to fly
            them in the flight simulator of your choice!
          </li>
          <li>
            <b>Emphasize your sceneries</b>: setup all the payware and freeware
            sceneries you have installed to make them a preferred origin or
            destination for future flights. It's always more fun to use than the
            default airports and without further effort you'll get interesting
            flights.
          </li>
          <li>
            <b>Recommendations</b>: don't know where to start and go to?
            FlySimRoutes has you covered with flight recommendations based on
            your most recent flights similar in distance and aircraft type ready
            to plan and fly in no time.
          </li>
          <li>
            <b>Simbrief integration</b>: briefing for each flight can be
            generated quickly through Simbrief, which also integrates with most
            of the existing payware and freeware aircraft add-ons for modern
            flight simulator software.
          </li>
        </ul>

        <Typography variant="h4" mt={5}>
          Get started with 3 simple steps:
        </Typography>

        <Stack direction="column" mt={1}>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Typography variant="h2" color="primary">
              01
            </Typography>
            <Stack>
              <Typography variant="h5">
                sign up with your social login
              </Typography>
              <Typography>
                Quick and easy registration with your social login. No need to
                go through the hassle of remembering another password.
              </Typography>
            </Stack>
          </Stack>

          <Stack direction="row" alignItems="center" spacing={2}>
            <Typography variant="h2" color="primary">
              02
            </Typography>
            <Stack>
              <Typography variant="h5">Choose the sceneries you own</Typography>
              <Typography>
                Payware and freeware - it doesn't matter. It's always more
                enjoyable to use a good scenery than using default airports.
              </Typography>
            </Stack>
          </Stack>

          <Stack direction="row" alignItems="center" spacing={2}>
            <Typography variant="h2" color="primary">
              03
            </Typography>
            <Stack>
              <Typography variant="h5">
                Search and schedule future flights and take off
              </Typography>
              <Typography>
                Create and adjust your briefing with Simbrief and take to the
                skies!
              </Typography>
            </Stack>
          </Stack>
        </Stack>

        <Stack sx={{ alignItems: "center" }} mt={5} mb={5}>
          <Button
            variant="contained"
            size="large"
            LinkComponent={Link}
            href="/login"
          >
            sign up now for free
          </Button>
        </Stack>
      </Container>
    </ScrollingLayout>
  );
}
