import { Button, Grid, Stack, Tooltip, Typography } from "@mui/material";
import FlightCard from "../atoms/FlightCard";
import { useState } from "react";
import SnackbarAlert, { Alert } from "../atoms/SnackbarAlert";
import request, { Variables } from "graphql-request";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { graphql } from "../gql/gql";
import { FlightsFilterInput } from "../gql/graphql";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import SaveIconButton from "../atoms/SaveIconButton";

const saveFlightPlan = graphql(/* GraphQL */ `
  mutation saveFlightplan($flightplan: SaveFlightplanInput!) {
    saveFlightplan(flightplan: $flightplan) {
      id
    }
  }
`);

type AirportFragment = {
  icao: string;
  iata?: string;
  name: string;
  country: string;
  latlng: {
    lat: number;
    lng: number;
  };
  scenery?: {
    developer: string;
    marketplace: string;
    rating: number;
  };
};

type FlightFragment = {
  departureTime: Date;
  airlineIcao: string;
  airline?: {
    logoUrl?: string;
  };
  number?: string;
  isSaved?: boolean;
  aircraftType: string;
  registration?: string;
  callsign?: string;
  distance: number;
  origin: AirportFragment;
  destination: AirportFragment;
  departureGate?: string;
  arrivalGate?: string;
  type?: string;
};

type DeletableFlight = FlightFragment & { id: string };

type FlightsCardGridProps = {
  origin?: AirportFragment;
  destination?: AirportFragment;
  onOriginChange: (icao?: string) => void;
  onDestinationChange: (icao?: string) => void;
  onResetFilters: () => void;
  onDeleteFlight?: (flight: DeletableFlight) => void;
  flights: FlightFragment[];
  filter: FlightsFilterInput;
  enableSave: boolean;
  enableDelete: boolean;
  canBeDeleted?: (flight: FlightFragment) => boolean;
};

export default function FlightsCardGrid(props: FlightsCardGridProps) {
  const queryClient = useQueryClient();
  const [message, setMessage] = useState<Alert | null>(null);

  const saveFlightplanMutation = useMutation({
    mutationFn: async (flightplan: Variables) =>
      request("/graphql", saveFlightPlan, flightplan),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["flightplans"] });
      setMessage({
        created: new Date(),
        message: "Successfully saved for later.",
      });
    },
  });

  const activeFilters = Object.values(props.filter).filter((x) => {
    return Array.isArray(x) ? x.length > 0 : !!x;
  });
  const filterEmpty = activeFilters.length === 1; // distance filter is always active

  return (
    <Stack sx={{ padding: "1rem", width: "100%" }}>
      <SnackbarAlert
        created={message?.created}
        message={message?.message}
        type="success"
        onClose={() => setMessage(null)}
      />

      <Grid container spacing={2}>
        {props.flights.length === 0 && !filterEmpty && (
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ textAlign: "center", width: "100%", marginTop: "1rem" }}
          >
            No flights found.{" "}
            <Button variant="text" onClick={props.onResetFilters}>
              Reset filters
            </Button>{" "}
            to see flights.
          </Typography>
        )}

        {filterEmpty && (
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ textAlign: "center", width: "100%", marginTop: "1rem" }}
          >
            Use filters above to search for flights.
          </Typography>
        )}

        {props.flights.map((flight) => (
          <Grid
            item
            xs={12}
            md={6}
            xl={4}
            key={flight.number + flight.departureTime}
          >
            <FlightCard
              flight={flight}
              actions={
                <>
                  {props.enableSave && (
                    <SaveIconButton
                      isSaved={flight.isSaved}
                      onClick={() => {
                        saveFlightplanMutation.mutate({
                          flightplan: {
                            timeGenerated: new Date(),
                            airline: flight.airlineIcao,
                            number: flight.number,
                            originIcao: flight.origin.icao,
                            destinationIcao: flight.destination.icao,
                            alternateIcao: null,
                            aircraftType: flight.aircraftType,
                            estimatedBlockTime: null,
                            registration: flight.registration,
                            callsign: flight.callsign,
                            type: "scheduled",
                            distance: flight.distance,
                            departureGate: flight.departureGate,
                            arrivalGate: flight.arrivalGate,
                          },
                        });
                      }}
                    />
                  )}
                  {!props.origin && (
                    <Tooltip title="Filter by departure">
                      <Button
                        onClick={() => props.onOriginChange(flight.origin.icao)}
                      >
                        <FilterAltIcon /> Depart {props.origin?.icao}
                      </Button>
                    </Tooltip>
                  )}
                  {!props.destination && (
                    <Tooltip title="Filter by arrival">
                      <Button
                        onClick={() =>
                          props.onDestinationChange(flight.destination.icao)
                        }
                      >
                        <FilterAltIcon /> Arrive
                        {props.destination?.icao}
                      </Button>
                    </Tooltip>
                  )}
                  {props.enableDelete && props.canBeDeleted(flight) && (
                    <Button
                      color="error"
                      onClick={() => {
                        props.onDeleteFlight?.(flight as DeletableFlight);
                      }}
                    >
                      <DeleteIcon />
                    </Button>
                  )}
                </>
              }
            />
          </Grid>
        ))}
      </Grid>
    </Stack>
  );
}
