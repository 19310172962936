import { Pagination as MuiPagination } from "@mui/material";

export type PaginationProps = {
  take: number;
  skip: number;
  total: number;
  onChange: (skip: number) => void;
};

export default function Pagination(props: PaginationProps) {
  const count = Math.ceil(props.total / props.take);
  const current = Math.ceil(props.skip / props.take) + 1;

  const pageToSkip = (page: number) => (page - 1) * props.take;

  return props.total > 0 ? (
    <MuiPagination
      count={count}
      page={current}
      color="primary"
      onChange={(_event, page: number) => props.onChange(pageToSkip(page))}
    />
  ) : null;
}
