import { Popper as MuiPopper } from "@mui/material";

const Popper = function (props) {
  return (
    <MuiPopper
      {...props}
      style={{ width: "fit-content" }}
      placement="bottom-start"
    />
  );
};

export default Popper;
