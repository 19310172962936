// @ts-ignore
import { Tooltip } from "react-leaflet/Tooltip";
import CountryFlag from "./CountryFlag";

export type AirportTooltipProps = {
  airport: {
    name: string;
    icao: string;
    iata?: string;
    country: string;
  };
};

export default function AirportTooltip(props: AirportTooltipProps) {
  return (
    <Tooltip direction="bottom">
      <CountryFlag country={props.airport.country} /> {props.airport.name}/
      {props.airport.iata} <strong>{props.airport.icao}</strong>
    </Tooltip>
  );
}
