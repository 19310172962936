import { Slider, Stack, Typography } from "@mui/material";
import { SyntheticEvent, useState } from "react";

type Range = {
  min?: number;
  max?: number;
};

export type DistanceSliderProps = {
  min: number;
  max: number;
  unlimitedMax: number;
  value: Range;
  markCount?: number;
  onChange: (values: Range) => void;
};

export default function DistanceSlider(props: DistanceSliderProps) {
  const values = props.value;

  values.min = props.value.min ?? props.min;
  values.max = props.value.max ?? props.max;

  const [range, setRange] = useState<Range>(values);
  const markCount = props.markCount ?? 4;

  const markSize = (props.max - props.min) / markCount;
  let marks = [];

  for (let i = 0; i < markCount; i++) {
    marks.push({
      value: Math.round(props.min + (i * markSize) / 100) * 100,
      label: (Math.round(props.min + (i * markSize) / 100) * 100).toString(),
    });
  }

  marks = [
    {
      value: props.min,
      label: props.min.toString(),
    },
    ...marks,
    {
      value: props.max,
      label: `> ${props.max}`,
    },
  ];

  const handleChangeCommitted = (
    _event: Event | SyntheticEvent<Element, Event>,
    value: number | number[]
  ) => {
    props.onChange({
      min: value[0],
      max: value[1] === props.max ? props.unlimitedMax : value[1],
    });
  };

  const valueText = (values: Range): string => {
    let from = values.min;
    let to = values.max;

    if (from === props.min && to >= props.max) {
      return "all";
    } else if (from === props.min) {
      return `< ${to} NM`;
    } else if (to >= props.max) {
      return `> ${from} NM`;
    }

    return `${from}-${to} NM`;
  };

  const labelFormat = (value: number): string => {
    return `${value} NM`;
  };

  return (
    <Stack direction="column" margin="0 1rem">
      <Typography id="distance-slider">Distance: {valueText(range)}</Typography>
      <Slider
        value={[range.min, range.max]}
        min={props.min}
        max={props.max}
        step={10}
        marks={marks}
        valueLabelDisplay="auto"
        valueLabelFormat={(value) => labelFormat(value)}
        getAriaLabel={() => "Distance range"}
        getAriaValueText={(number, _index) => number.toString()}
        onChange={(_, newValue) =>
          setRange({ min: newValue[0], max: newValue[1] })
        }
        onChangeCommitted={handleChangeCommitted}
      />
    </Stack>
  );
}
