import { Button, Grid, Paper, Stack, Typography } from "@mui/material";
import Recommendations from "../components/Recommendations";
import { Link, useLoaderData } from "react-router-dom";
import ScrollingLayout from "../components/ScrollingLayout";

export default function DashboardPage() {
  const { profile } = useLoaderData() as { profile?: { name: string } };

  return (
    <ScrollingLayout>
      <Grid container spacing={2} paddingLeft={1} paddingRight={1}>
        <Grid item xs={12} md={6}>
          <Typography variant="h5" gutterBottom>
            Welcome back, {profile.name}!
          </Typography>

          <Paper sx={{ padding: "1rem" }}>
            <Stack spacing={4}>
              <Button
                component={Link}
                to="/flights"
                variant="contained"
                size="large"
              >
                Flights
              </Button>
              <Typography variant="body2" sx={{ textAlign: "center" }}>
                Search for new flights to fly and sceneries to explore. Flying
                based on real-world operations by Airlines worldwide.
              </Typography>

              <Button
                component={Link}
                to="/flightplans"
                variant="contained"
                size="large"
              >
                Saved Flights
              </Button>
              <Typography variant="body2" sx={{ textAlign: "center" }}>
                Or manage your saved flights or choose one to fly today.
              </Typography>

              <Button
                component={Link}
                to="/sceneries"
                variant="contained"
                size="large"
              >
                Sceneries
              </Button>
              <Typography variant="body2" sx={{ textAlign: "center" }}>
                Manage your purchased and freeware sceneries. Explore the world
                with high quality sceneries. Get recommendations to fly to new
                places from and to your purchased sceneries.
              </Typography>
            </Stack>
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Recommendations />
        </Grid>
      </Grid>
    </ScrollingLayout>
  );
}
