import {
  Box,
  Button,
  SpeedDial,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import SceneryMap from "../components/SceneryMap";
import Loading from "../atoms/Loading";
import SnackbarAlert, { Alert } from "../atoms/SnackbarAlert";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ConfirmDialog from "../atoms/ConfirmDialog";
import request, { Variables } from "graphql-request";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import Breadcrumbs from "../atoms/Breadcrumbs";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import FolderCopyIcon from "@mui/icons-material/FolderCopy";
import { SceneryDetailsFragment, SceneryPackage } from "../gql/graphql";
import SceneryPackagesDialog from "../components/SceneryPackagesDialog";
import ScrollingLayout from "../components/ScrollingLayout";
import { useDebouncedCallback } from "use-debounce";
import Pagination from "../atoms/Pagination";
import SceneriesTable from "../components/SceneriesTable";
import { graphql } from "../gql/gql";
import { useFragment } from "../gql";
import SceneryCardGrid from "../components/SceneryCardGrid";
import CountryFlag from "../atoms/CountryFlag";

export interface SceneryDataDisplayProps {
  sceneries: readonly SceneryDetailsFragment[];
  onSceneryPackageRemove: (sceneryPackage: SceneryPackage) => void;
  onRatingChange: (scenery: SceneryDetailsFragment, rating: number) => void;
  onSceneryDelete: (scenery: SceneryDetailsFragment) => void;
}

const SceneryFragment = graphql(/* GraphQL */ `
  fragment SceneryDetails on Scenery {
    id
    icao
    platform
    developer
    marketplace
    rating
    package {
      id
      name
    }
    airport {
      iata
      icao
      name
      country
      type
      latlng {
        lat
        lng
      }
    }
  }
`);

const allSceneriesPaginated = graphql(/* GraphQL */ `
  query sceneries($query: String!, $take: Int!, $skip: Int!) {
    sceneries(query: $query, pagination: { take: $take, skip: $skip }) {
      total
      take
      skip
      results {
        ...SceneryDetails
      }
    }
  }
`);

const removeSceneryPackages = graphql(/* GraphQL */ `
  mutation removeSceneryPackages($ids: [ID!]!) {
    removeSceneryPackages(ids: $ids) {
      id
    }
  }
`);

const deleteScenery = graphql(/* GraphQL */ `
  mutation deleteScenery($id: ID!) {
    deleteScenery(id: $id)
  }
`);

const rateScenery = graphql(/* GraphQL */ `
  mutation rateScenery($id: ID!, $rating: Int!) {
    rateScenery(id: $id, rating: $rating) {
      rating
    }
  }
`);

export default function SceneriesPage() {
  const navigate = useNavigate();
  const [searchInput, setSearchInput] = useState<string>("");
  const [query, setQuery] = useState<string>("");
  const [skip, setSkip] = useState<number>(0);
  const [error, setError] = useState<Alert | null>(null);
  const [ratingSuccessMessage, setRatingSuccessMessage] = useState<string>(
    "Scenery saved successfully."
  );
  const [message, setMessage] = useState<Alert | null>(null);
  const [sceneryToDelete, setSceneryToDelete] =
    useState<SceneryDetailsFragment | null>(null);
  const [sceneryPacksToRemove, setSceneryPacksToRemove] =
    useState<SceneryPackage | null>(null);
  const [sceneryPackagesDialogOpen, setSceneryPackagesDialogOpen] =
    useState<boolean>(false);

  const queryClient = useQueryClient();

  const take = 25;
  const theme = useTheme();
  const breakpointTable = useMediaQuery(theme.breakpoints.up("lg"));
  const DataDisplayComponent = breakpointTable
    ? SceneriesTable
    : SceneryCardGrid;

  const actions = [
    {
      icon: <AddIcon />,
      name: "Add Custom Scenery",
      action: () => navigate("/sceneries/new"),
    },
    {
      icon: <FolderCopyIcon />,
      name: "Add Scenery Package",
      action: () => setSceneryPackagesDialogOpen(true),
    },
  ];

  const {
    data,
    isLoading,
    error: sceneryError,
    errorUpdatedAt,
  } = useQuery({
    queryKey: ["sceneries", query, take, skip],
    queryFn: async () =>
      request("/graphql", allSceneriesPaginated, {
        query,
        take,
        skip,
      }),
  });

  useEffect(() => {
    setSkip(0);
  }, [query]);

  const deleteSceneryMutation = useMutation({
    mutationFn: async () =>
      request("/graphql", deleteScenery, { id: sceneryToDelete?.id }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["sceneries"] });
      setSceneryToDelete(null);
    },
  });

  const removeSceneryPackagesMutation = useMutation({
    mutationFn: async () =>
      request("/graphql", removeSceneryPackages, {
        ids: [sceneryPacksToRemove.id],
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["sceneries"] });
      setSceneryPacksToRemove(null);
    },
  });

  const rateSceneryMutation = useMutation({
    mutationFn: async (variables: Variables) =>
      request("/graphql", rateScenery, variables),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["sceneries"] });
      setMessage({
        created: new Date(),
        message: ratingSuccessMessage,
      });
    },
    onError: (error) => {
      setError({
        created: new Date(),
        message: "Failed to rate scenery, please try again later.",
      });
    },
  });

  const debouncedQuery = useDebouncedCallback((value: string) => {
    setQuery(value);
  }, 300);

  useEffect(() => {
    debouncedQuery(searchInput);
  }, [searchInput, debouncedQuery]);

  const sceneries = useFragment(SceneryFragment, data?.sceneries?.results);

  return (
    <ScrollingLayout>
      <SpeedDial
        ariaLabel="Scenery Actions"
        sx={{ position: "fixed", bottom: 16, right: 16 }}
        icon={<SpeedDialIcon />}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={action.action}
          />
        ))}
      </SpeedDial>

      <SceneryPackagesDialog
        open={sceneryPackagesDialogOpen}
        onClose={() => setSceneryPackagesDialogOpen(false)}
      />

      <SnackbarAlert
        created={error?.created ?? errorUpdatedAt}
        message={
          error?.message ??
          (sceneryError
            ? "Error loading sceneries. Please try again later."
            : null)
        }
        onClose={() => null}
        type="error"
      />

      <SnackbarAlert
        created={message?.created}
        message={message?.message}
        onClose={() => null}
        type="success"
      />

      {sceneryToDelete && (
        <ConfirmDialog
          title="Delete Scenery"
          content={
            <>
              Are you sure you want to delete scenery for{" "}
              <strong>{sceneryToDelete.icao}</strong>{" "}
              {sceneryToDelete.airport.name}/{sceneryToDelete.airport.iata}{" "}
              <CountryFlag country={sceneryToDelete.airport.country} />?
            </>
          }
          onConfirm={() => {
            deleteSceneryMutation.mutate();
          }}
          onClose={function (): void {
            setSceneryToDelete(null);
          }}
          open={!!sceneryToDelete}
        />
      )}

      {sceneryPacksToRemove && (
        <ConfirmDialog
          title="Remove Scenery Package"
          content={`Are you sure you want to remove the scenery package for ${sceneryPacksToRemove.name}?`}
          onConfirm={() => {
            removeSceneryPackagesMutation.mutate();
          }}
          onClose={function (): void {
            setSceneryPacksToRemove(null);
          }}
          open={!!sceneryPacksToRemove}
        />
      )}

      <Stack direction="column">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Breadcrumbs
            pages={[
              { name: "Dashboard", href: "/dashboard" },
              { name: "Sceneries" },
            ]}
          />

          <TextField
            label="Search"
            variant="outlined"
            size="small"
            value={searchInput}
            onChange={(event) => {
              setSearchInput(event.target.value);
            }}
          />
        </Stack>

        <Box height={300}>
          <SceneryMap sceneries={sceneries?.filter((s) => s.airport) ?? []} />
        </Box>

        {isLoading ? (
          <Loading loading={true} />
        ) : (
          <>
            {data?.sceneries?.results.length === 0 && !!query && (
              <Stack mt={2}>
                <Typography textAlign="center">
                  No sceneries found for "{query}".{" "}
                  <Button
                    variant="text"
                    onClick={() => {
                      setQuery("");
                      setSearchInput("");
                    }}
                  >
                    Clear search
                  </Button>
                </Typography>
              </Stack>
            )}

            {data?.sceneries?.results.length === 0 && !error && !query && (
              <Stack mt={2}>
                <Typography textAlign="center">
                  You don't have any sceneries yet.
                </Typography>

                <Typography
                  variant="body2"
                  color="text.secondary"
                  textAlign="center"
                  mt={2}
                >
                  Setting up your sceneries will improve your experience and
                  make your flights more realistic. Add a{" "}
                  <Button
                    variant="text"
                    onClick={() => setSceneryPackagesDialogOpen(true)}
                  >
                    package
                  </Button>{" "}
                  or a{" "}
                  <Button
                    variant="text"
                    onClick={() => navigate("/sceneries/new")}
                  >
                    custom scenery
                  </Button>{" "}
                  to get started. some to get started.
                </Typography>
              </Stack>
            )}
            {data?.sceneries?.results.length > 0 && (
              <Stack direction="column" alignItems="center" spacing={1} mt={1}>
                <Pagination
                  take={data?.sceneries?.take}
                  skip={data?.sceneries?.skip}
                  total={data?.sceneries?.total}
                  onChange={(skip) => setSkip(skip)}
                />

                <DataDisplayComponent
                  sceneries={sceneries}
                  onSceneryPackageRemove={(p) => setSceneryPacksToRemove(p)}
                  onRatingChange={(scenery, rating) => {
                    if (rating === 0) {
                      setRatingSuccessMessage("Rating removed successfully.");
                    } else if (rating <= 2) {
                      setRatingSuccessMessage(
                        "It's that bad? Sorry to hear that."
                      );
                    } else if (rating <= 3) {
                      setRatingSuccessMessage(
                        "Seems okay, but could be better."
                      );
                    } else if (rating >= 5) {
                      setRatingSuccessMessage(
                        "Awesome! Thanks for the rating."
                      );
                    } else if (rating >= 4) {
                      setRatingSuccessMessage("Great! Thanks for the rating.");
                    } else {
                      setRatingSuccessMessage(
                        "Thanks for rating this scenery."
                      );
                    }

                    rateSceneryMutation.mutate({
                      id: scenery.id,
                      rating: rating,
                    });
                  }}
                  onSceneryDelete={(scenery) => setSceneryToDelete(scenery)}
                />

                <Pagination
                  take={data?.sceneries?.take}
                  skip={data?.sceneries?.skip}
                  total={data?.sceneries?.total}
                  onChange={(skip) => setSkip(skip)}
                />
              </Stack>
            )}
          </>
        )}
      </Stack>
    </ScrollingLayout>
  );
}
