import { Autocomplete, Box, TextField } from "@mui/material";
import { useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import request from "graphql-request";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { graphql } from "../gql/gql";
import AirportOption from "../atoms/AirportOption";
import { LatLngLiteral } from "leaflet";
import Popper from "../atoms/Popper";

const searchAirports = graphql(/* GraphQL */ `
  query searchAirports($query: String!, $take: Int!) {
    searchAirports(query: $query, pagination: { take: $take }) {
      icao
      iata
      name
      country
      latlng {
        lat
        lng
      }
    }
  }
`);

export type AirportAutocompleteProps = {
  airports: SelectedAirport[];
  label: string;
  error: boolean;
  helperText: string;
  onAirportSelect: (airport: SelectedAirport) => void;
  distanceReference?: LatLngLiteral;
};

const defaultHelperText = "ICAO, IATA, or airport name";

export type SelectedAirport = {
  icao: string;
  iata?: string;
  name: string;
  country: string;
  latlng: {
    lat: number;
    lng: number;
  };
};

export default function AirportAutocomplete(props: AirportAutocompleteProps) {
  const queryClient = useQueryClient();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const { isLoading, error, data } = useQuery({
    queryKey: ["airports"],
    queryFn: async () =>
      request("/graphql", searchAirports, { query: searchTerm, take: 10 }),
  });

  const debounced = useDebouncedCallback((value) => {
    if (value.length > 0) {
      queryClient.invalidateQueries({ queryKey: ["airports"] });
    } else {
      setSearchTerm("");
    }
  }, 300);

  let availableOptions = data?.searchAirports ?? props.airports;

  return (
    <Autocomplete
      id="airport_search"
      fullWidth
      filterOptions={(x) => x}
      PopperComponent={Popper}
      options={availableOptions}
      isOptionEqualToValue={(
        option: SelectedAirport,
        value: SelectedAirport
      ) => {
        return option.icao === value.icao;
      }}
      getOptionKey={(option: SelectedAirport) => option.icao}
      getOptionLabel={(option: SelectedAirport) =>
        `${option.icao} ${option.name}/${option.iata}`
      }
      loading={isLoading}
      renderOption={(p, option) => (
        <Box component="li" {...p}>
          <AirportOption
            airport={option}
            distanceReference={props.distanceReference}
          />
        </Box>
      )}
      inputValue={searchTerm}
      onChange={(event: any, newValue: SelectedAirport | null) => {
        if (!newValue) {
          return;
        }

        props.onAirportSelect(newValue);
      }}
      onInputChange={(event: any, newValue: string | null) => {
        setSearchTerm(newValue);
        debounced(newValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={props.label}
          aria-describedby="airport_search"
          error={props.error || !!error}
          helperText={
            props.helperText ??
            (error ? "Error loading airports" : defaultHelperText)
          }
        />
      )}
    />
  );
}
