import { Tooltip } from "@mui/material";
import { flag, name } from "country-emoji";

export type CountryFlagProps = {
  country: string;
};

export default function CountryFlag(props: CountryFlagProps) {
  return (
    <Tooltip title={name(props.country)} arrow>
      <span>{flag(props.country)}</span>
    </Tooltip>
  );
}
