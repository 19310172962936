import { Link, Stack, Typography, useTheme } from "@mui/material";

export default function Copyright(props: any) {
  const theme = useTheme();

  return (
    <Stack direction="row" spacing={1}>
      <Typography
        {...props}
        color={theme.palette.text.disabled}
        fontSize="0.8rem"
      >
        &copy;{" "}
      </Typography>
      <Typography
        {...props}
        color={theme.palette.text.disabled}
        fontSize="0.8rem"
      >
        <Link color="inherit" href="https://flightplanning.liebler.dev/">
          FlySimRoutes
        </Link>{" "}
        {new Date().getFullYear()}
        <br />
        All rights reserved.
      </Typography>
    </Stack>
  );
}
