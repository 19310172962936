import { Airline } from "./gql/graphql";
import { Aircraft } from "../models/aircraft";

export type FlightFilterInput = {
  originIcao?: string;
  destinationIcao?: string;
  aircrafts?: Aircraft[];
  airlines?: Airline[];
  distance?: { min?: number; max?: number };
};

export function filterToURL(filter: FlightFilterInput): URLSearchParams {
  const params = new URLSearchParams();

  if (filter.originIcao) {
    params.append("originIcao", filter.originIcao);
  }

  if (filter.destinationIcao) {
    params.append("destinationIcao", filter.destinationIcao);
  }

  if (filter.distance?.min) {
    params.append("distanceMin", filter.distance.min.toString());
  }

  if (filter.distance?.max && filter.distance.max < 10000) {
    params.append("distanceMax", filter.distance.max.toString());
  }

  if (filter.airlines && filter.airlines.length > 0) {
    params.append("airlines", filter.airlines.map((a) => a.icao).join(","));
  }

  if (filter.aircrafts && filter.aircrafts.length > 0) {
    params.append("aircrafts", filter.aircrafts.map((a) => a.id).join(","));
  }

  return params;
}

export function urlToFilter(params: URLSearchParams): FlightFilterInput {
  const filter = { distance: { min: 0, max: 10000 } } as FlightFilterInput;

  if (params.has("originIcao")) {
    filter.originIcao = params.get("originIcao")!;
  }

  if (params.has("destinationIcao")) {
    filter.destinationIcao = params.get("destinationIcao")!;
  }

  if (params.has("distanceMin")) {
    filter.distance.min = parseInt(params.get("distanceMin")!);
  }

  if (params.has("distanceMax")) {
    filter.distance.max = parseInt(params.get("distanceMax")!);
  }

  if (params.has("airlines") && params.get("airlines").trim() !== "") {
    filter.airlines = params
      .get("airlines")!
      .split(",")
      .map((icao) => ({ icao })) as Airline[];
  }

  if (params.has("aircrafts") && params.get("aircrafts").trim() !== "") {
    filter.aircrafts = params
      .get("aircrafts")!
      .split(",")
      .map((id) => ({ id })) as Aircraft[];
  }

  return filter;
}
