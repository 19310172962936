import {
  IconButton,
  Paper,
  Rating,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from "@mui/material";
import { StyledTableRow } from "../atoms/Table";
import ColoredChip from "../atoms/ColoredChip";
import CountryFlag from "../atoms/CountryFlag";
import { Link as RouterLink } from "react-router-dom";
import { SceneryPackage } from "../gql/graphql";
import EditIcon from "@mui/icons-material/Edit";
import { SceneryDataDisplayProps } from "../pages/SceneriesPage";
import SceneriesOptionsMenu from "../atoms/SceneriesOptionsMenu";

export default function SceneriesTable(props: SceneryDataDisplayProps) {
  const theme = useTheme();

  return (
    <TableContainer component={Paper}>
      <Table aria-label="sceneries" stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>ICAO</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Platform</TableCell>
            <TableCell>Developer</TableCell>
            <TableCell>Marketplace</TableCell>
            <TableCell>Rating</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.sceneries.map((row) => (
            <StyledTableRow theme={theme} key={row.id}>
              <TableCell component="th" scope="row">
                {row.icao}{" "}
                {row.package && (
                  <ColoredChip
                    onDelete={() => {
                      props.onSceneryPackageRemove(
                        row.package as SceneryPackage
                      );
                    }}
                    label={row.package.name}
                    size="small"
                  />
                )}
              </TableCell>
              <TableCell>
                {row.airport?.name}{" "}
                <CountryFlag country={row.airport?.country} />
              </TableCell>
              <TableCell>
                <ColoredChip label={row.platform} size="small" />
              </TableCell>
              <TableCell>{row.developer}</TableCell>
              <TableCell>{row.marketplace}</TableCell>
              <TableCell>
                <Rating
                  value={row.rating}
                  onChange={(_event: any, newValue: number) => {
                    const newRating = newValue ?? 0;
                    props.onRatingChange(row, newRating);
                  }}
                />
              </TableCell>
              <TableCell sx={{ whiteSpace: "nowrap" }}>
                <IconButton
                  color="primary"
                  component={RouterLink}
                  to={`/sceneries/${row.id}`}
                >
                  <EditIcon />
                </IconButton>

                <SceneriesOptionsMenu
                  scenery={row}
                  onDelete={() => {
                    props.onSceneryDelete(row);
                  }}
                />
              </TableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
